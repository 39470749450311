import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dpnMissingDescription: {
    id: "billing.subscriptionAddonCard.dpnMissingDescription",
    defaultMessage: "Dedicated phone number is required to use AI Receptionist",
    description: "dpn missing message text",
  },
  setUp: {
    id: "billing.subscriptionAddonCard.setUp",
    defaultMessage: "Set one up",
    description: "dpn missing set up button text",
  },
  setUpAriaLabel: {
    id: "billing.subscriptionAddonCard.setUpAriaLabel",
    defaultMessage: "Set a dedicated phone number up",
    description: "dpn missing set up aria label",
  },
});
