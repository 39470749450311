import React from "react";
import { useIntl } from "react-intl";
import { Flex, Typography } from "@jobber/components";
import { Tooltip } from "@jobber/components/Tooltip";
import blueBackground from "@images/signup/blue-bg-w-effect.png";
import yourHappyCustomerSrc from "@images/signup/your-happy-customer.svg";
import GoogleLogo from "@images/signup/google-logo-circled.svg";
import { messages } from "jobber/setupWizard/messages";
import { StarGroup } from "jobber/reviews/views/ReviewsPage/components/StarGroup";
import styles from "./Review.module.css";

const Review = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.reviewWrapper}>{children}</div>
);

const MaskingTapeContent = ({ children }: { children: React.ReactNode }) => {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.maskingTapeWrapper}>
      <img
        src={blueBackground}
        alt={formatMessage(messages.maskingTapeBgAltText)}
        className={styles.backgroundImg}
      />
      <img
        src={yourHappyCustomerSrc}
        alt={formatMessage(messages.didYouKnowAltText)}
        className={styles.beforeImg}
      />
      {children}
    </div>
  );
};

const ReviewText = ({ text }: { text: string }) => {
  return (
    <Tooltip message={text}>
      <Typography
        element="h2"
        fontWeight="extraBold"
        numberOfLines={5}
        textColor={"white"}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};

const AdditionalInfo = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.authorInfoWrapper}>{children}</div>
);

const Rating = ({ rating }: { rating: number }) => {
  const { formatMessage } = useIntl();
  return (
    <Flex align="center" gap="small" template={["grow", "shrink"]}>
      <img
        src={GoogleLogo}
        alt={formatMessage(messages.googleLogoAltText)}
        className={styles.googleLogo}
      />
      <StarGroup rating={rating} allowHalfStars={true} starSize="base" />
    </Flex>
  );
};

const Author = ({
  name,
  companyName,
}: {
  name: string;
  companyName: string;
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Typography element="h3" fontWeight="extraBold" textColor={"white"}>
        {formatMessage(messages.reviewAuthorCompanyCustomerText, {
          companyName,
        })}
      </Typography>
      <Typography textColor={"white"}>{name}</Typography>
    </>
  );
};

Review.MaskingTapeContent = MaskingTapeContent;
Review.Text = ReviewText;
Review.AdditionalInfo = AdditionalInfo;
Review.Author = Author;
Review.Rating = Rating;

export { Review };
