import React, { type MutableRefObject, useRef, useState } from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Popover } from "@jobber/components/Popover";
import { Text } from "@jobber/components/Text";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./CoreToConnectTrialSettingsPopovers.module.css";

interface CoreToConnectTrialSettingsPopoversProps {
  selfServeBookingsEnabled: boolean;
}

export function CoreToConnectTrialSettingsPopovers({
  selfServeBookingsEnabled,
}: CoreToConnectTrialSettingsPopoversProps) {
  const [popover, setPopover] = useState(0);

  const ctaName = "core_to_connect_trial_settings_highlights_cta";
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;
  let totalSteps = 4;
  const popoverContent = [
    {
      elementSelector: `a:contains("Custom Fields")`,
      heading: "Custom Fields",
      content:
        "Use custom fields to track whatever additional information is important to you, like entry codes or pets at the property.",
      buttonLabel: "Next",
      primaryAction: handleNextPopover,
    },
    {
      elementSelector: `a:contains("Route Optimization")`,
      heading: "Route Optimization",
      content:
        "Visualize your visits for the day, and automatically generate the fastest, most fuel-efficient route.",
      buttonLabel: "Next",
      primaryAction: handleNextPopover,
    },
    {
      elementSelector: `a:contains("Job Forms")`,
      heading: "Job Forms",
      content:
        "Custom forms and checklists ensure your team delivers consistent service, and can be sent to customers to confirm what was done.",
      buttonLabel: "Next",
      primaryAction: handleNextPopover,
    },
  ];

  if (selfServeBookingsEnabled) {
    totalSteps = 5;
    popoverContent.push(
      {
        elementSelector: `a:contains("Emails & Text Messages")`,
        heading: "Emails & Text Messages",
        content:
          "Stay connected with customers anytime with less effort. Turn on automated updates to alert them to documents requiring their attention.",
        buttonLabel: "Next",
        primaryAction: handleNextPopover,
      },
      {
        elementSelector: `a:contains("Online Booking")`,
        heading: "Online Booking",
        content:
          "Save time by letting customers book an appointment with you online. When booked, Jobber will automatically schedule the job based on your preferences.",
        buttonLabel: "Got it",
        primaryAction: convertCTA(ctaRef, {
          type: `popover_step_${totalSteps}`,
        }),
      },
    );
  } else {
    popoverContent.push({
      elementSelector: `a:contains("Emails & Text Messages")`,
      heading: "Emails & Text Messages",
      content:
        "Stay connected with customers anytime with less effort. Turn on automated updates to alert them to documents requiring their attention.",
      buttonLabel: "Got it",
      primaryAction: convertCTA(ctaRef, {
        type: `popover_step_${totalSteps}`,
      }),
    });
  }

  return (
    <div className={styles.coreToConnectTrialSettingsPopovers}>
      <APIProvider>
        <CallToAction ctaName={ctaName} ref={ctaRef}>
          <TrialPopover
            elementSelector={popoverContent[popover].elementSelector}
            heading={popoverContent[popover].heading}
            content={popoverContent[popover].content}
            popoverIndex={popover + 1}
            buttonLabel={popoverContent[popover].buttonLabel}
            primaryAction={popoverContent[popover].primaryAction}
            dismissPopover={dismissCTA(ctaRef, {
              type: `popover_step_${popover + 1}`,
            })}
            totalSteps={totalSteps}
          />
        </CallToAction>
      </APIProvider>
    </div>
  );

  function handleNextPopover() {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: `${ctaName}_next_button`,
      source: "settings",
      type: `popover_step_${popover + 1}`,
    });
    setPopover(popover + 1);
  }
}

interface TrialPopoverProps {
  elementSelector: string;
  heading: string;
  content: string;
  popoverIndex: number;
  buttonLabel: string;
  primaryAction(): void;
  dismissPopover(): void;
  totalSteps: number;
}

function TrialPopover({
  elementSelector,
  heading,
  content,
  popoverIndex,
  buttonLabel,
  primaryAction,
  dismissPopover,
  totalSteps,
}: TrialPopoverProps) {
  const element = findElement(elementSelector);

  return element ? (
    <Popover
      UNSAFE_style={{ container: { maxWidth: "360px" } }}
      attachTo={element}
      open={true}
      preferredPlacement="right"
      onRequestClose={dismissPopover}
    >
      <Content spacing="base">
        <Heading level={5}>{heading}</Heading>
        <Text size="small">{content}</Text>
        <div className={styles.row}>
          <Text size="small" variation="subdued">
            {popoverIndex} of {totalSteps}
          </Text>
          <Button
            variation="learning"
            label={buttonLabel}
            onClick={primaryAction}
          />
        </div>
      </Content>
    </Popover>
  ) : (
    <></>
  );
}

function findElement(elementSelector: string) {
  const elements = $(elementSelector);
  if (elements) {
    return elements[0];
  }
}
