import React, { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import type { SortingState } from "@jobber/components/DataTable";
import { formatISO } from "date-fns";
import type { FilterType } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/types";
import { fetchRoyaltySorting } from "jobber/franchise/features/Reporting/views/RoyaltyReport/hooks/useRoyaltyReportSorting";
import {
  type ReportExportMutation,
  type ReportExportMutationVariables,
  ReportTypeEnum,
  type RoyaltyQuery,
  RoyaltySortKey,
  type RoyaltyTotalsQuery,
  SortDirectionEnum,
} from "~/utilities/API/graphqlFranchiseManagement";
import { defaultDateRangeOption } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/components/DateSelector/constants";
import { REPORT_EXPORT_MUTATION } from "jobber/franchise/features/Reporting/views/constants/Report.graphql";
import { SplitNames, useFeatureFlag, withSplitClient } from "utilities/split";
import { RoyaltyReport } from "./RoyaltyReport";
import type { RoyaltyData, RoyaltyTotalsData } from "./types";
import { ROYALTY_QUERY, ROYALTY_TOTALS_QUERY } from "./RoyaltyReport.graphql";
import { PAGE_NUMBER_OPTIONS } from "./constants";

// eslint-disable-next-line max-statements
export const RoyaltyReportLoader = withSplitClient(() => {
  const [reportFilter, setReportFilter] = useState<FilterType>({
    key: "date_range",
    value: {
      startDate: defaultDateRangeOption.start,
      endDate: defaultDateRangeOption.end,
    },
  });

  const [reportIsExported, setReportIsExported] = useState(false);

  const [sortingState, setSortingState] = useState([
    {
      id: "FRANCHISE_NAME",
      desc: false,
    },
  ]);

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: PAGE_NUMBER_OPTIONS[0],
  });

  const [indexedCursors, setIndexedCursors] = useState<[string | undefined]>([
    undefined,
  ]);

  const isRoyaltyReportFixEnabled = useFeatureFlag(
    SplitNames.FranchiseRoyaltyReportTimezoneFix,
  );

  function createCompleteISOString(date: Date): string {
    return isRoyaltyReportFixEnabled
      ? formatISO(date, { representation: "complete" })
      : date.toISOString();
  }

  const [getData, { data, loading, error }] =
    useLazyQuery<RoyaltyQuery>(ROYALTY_QUERY);

  useEffect(() => {
    fetchRoyaltySorting(getData, {
      variables: {
        sort: {
          key: sortingState[0]?.id || "FRANCHISE_NAME",
          direction: sortingState[0]?.desc ? "DESCENDING" : "ASCENDING",
        },
        filter: {
          ...reportFilter.value,
          startDate: createCompleteISOString(reportFilter?.value.startDate),
          endDate: createCompleteISOString(reportFilter?.value.endDate),
        },
        cursor: indexedCursors[pageIndex],
        first: pageSize,
      },
    });
  }, [sortingState, reportFilter, pageIndex, pageSize]);

  const royaltyRecords = data?.royalties?.records as RoyaltyData[];

  const [
    getRoyaltyTotals,
    { data: totalsData, loading: totalsLoading, error: totalsError },
  ] = useLazyQuery<RoyaltyTotalsQuery>(ROYALTY_TOTALS_QUERY);

  const royaltyTotals: RoyaltyTotalsData | undefined =
    totalsData?.royaltyTotals;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getRoyaltyTotals({
      variables: {
        filter: {
          ...reportFilter.value,
          startDate: createCompleteISOString(reportFilter?.value.startDate),
          endDate: createCompleteISOString(reportFilter?.value.endDate),
        },
      },
    });
  }, [getRoyaltyTotals, reportFilter]);

  const [exportCsv] = useMutation<
    ReportExportMutation,
    ReportExportMutationVariables
  >(REPORT_EXPORT_MUTATION, {
    variables: {
      reportType: ReportTypeEnum.ROYALTY,
      sort: {
        key:
          (sortingState[0]?.id as RoyaltySortKey) ||
          RoyaltySortKey.FRANCHISE_NAME,
        direction: sortingState[0]?.desc
          ? SortDirectionEnum.DESCENDING
          : SortDirectionEnum.ASCENDING,
      },
      filter: {
        startDate: createCompleteISOString(
          reportFilter?.value.startDate || defaultDateRangeOption.start,
        ),
        endDate: createCompleteISOString(
          reportFilter?.value.endDate || defaultDateRangeOption.end,
        ),
      },
    },
  });

  async function exportFunction() {
    const { errors } = await exportCsv();
    setReportIsExported(!errors);
  }

  const totalItems = data?.royalties?.totalCount || 0;
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  useMemo(() => {
    if (data?.royalties.reportPageInfo) {
      const newCursorIndex = indexedCursors;

      if (data.royalties.reportPageInfo.startCursor) {
        newCursorIndex[pageIndex] = data.royalties.reportPageInfo.startCursor;
      }

      if (data.royalties.reportPageInfo.nextCursor) {
        newCursorIndex[pageIndex + 1] =
          data.royalties.reportPageInfo.nextCursor;
      }

      setIndexedCursors(newCursorIndex);
    }
  }, [data]);

  const handleDateFilterChange = (range: FilterType) => {
    setIndexedCursors([undefined]);
    setPagination({ pageIndex: 0, pageSize: pageSize });
    setReportFilter(range);
  };

  const handleSortChange = (sortState: SortingState) => {
    setIndexedCursors([undefined]);
    setPagination({ pageIndex: 0, pageSize: pageSize });
    setSortingState(sortState);
  };

  return (
    <RoyaltyReport
      data={royaltyRecords}
      totalsData={royaltyTotals}
      loading={loading || totalsLoading}
      error={error?.message || totalsError?.message}
      filterProps={{
        dateRangeFilter: reportFilter,
        onFilterChange: handleDateFilterChange,
      }}
      sortingProps={{
        manualSorting: true,
        state: sortingState,
        onSortingChange: handleSortChange,
      }}
      paginationProps={{
        manualPagination: true,
        onPaginationChange: setPagination,
        itemsPerPage: PAGE_NUMBER_OPTIONS,
        totalItems,
        state: pagination,
        pageCount: Math.ceil(totalItems / pageSize),
      }}
      exportProps={{
        exportFunction: exportFunction,
        setReportIsExported: setReportIsExported,
        reportIsExported: reportIsExported,
      }}
    />
  );
});
