import React, { useEffect, useRef, useState } from "react";
import { Flex, Heading, Icon, Tab, Tabs, Tooltip } from "@jobber/components";
import { useIntl } from "react-intl";
import { TemplatePreviewer } from "~/components/NotificationTemplates/TemplatePreviewer/TemplatePreviewer";
import type { MessageTemplate } from "~/components/NotificationTemplates/types";
import type {
  DeliveryMethod,
  NotificationDeliveryMethod,
} from "~/utilities/API/graphql";
import { StarRatingImage } from "./images";
import { messages } from "./messages";
import styles from "./ReviewMessagePreviewer.module.css";

interface ReviewMessagePreviewerProps {
  deliveryMethod: DeliveryMethod | NotificationDeliveryMethod;
  staticTemplate?: MessageTemplate;
  templates: MessageTemplate[];
}

interface MessagePreviewProps {
  hasDPN?: boolean;
  staticTemplate?: MessageTemplate;
  template: MessageTemplate;
}

export function ReviewMessagePreviewer({
  deliveryMethod,
  templates,
  staticTemplate,
}: ReviewMessagePreviewerProps): JSX.Element {
  const { formatMessage } = useIntl();
  const templateStoreRef = useRef(templates);
  const [templatesToDisplay, setTemplatesToDisplay] = useState<
    MessageTemplate[]
  >(
    templateStoreRef.current.filter(
      template => template.deliveryMethod === deliveryMethod,
    ),
  );

  useEffect(() => {
    const displayedTemplate =
      templateStoreRef.current.find(
        template => template.deliveryMethod === deliveryMethod,
      ) || undefined;

    setTemplatesToDisplay(
      displayedTemplate !== undefined
        ? [displayedTemplate]
        : templateStoreRef.current,
    );
  }, [deliveryMethod]);

  return (
    <Tabs defaultTab={templatesToDisplay.length > 1 ? 1 : 0}>
      {templatesToDisplay.map(template => (
        <Tab
          key={template.id}
          label={
            template.deliveryMethod === "EMAIL"
              ? formatMessage(messages.emailTabTitle)
              : formatMessage(messages.textMessageTabTitle)
          }
        >
          <MessagePreviewer
            template={template}
            staticTemplate={staticTemplate}
          />
        </Tab>
      ))}
    </Tabs>
  );
}

export function MessagePreviewer({
  template,
  staticTemplate,
  hasDPN = false,
}: MessagePreviewProps): JSX.Element {
  const { formatMessage } = useIntl();
  const isSMS = template.deliveryMethod === "SMS";
  const staticImage = !isSMS ? (
    <div className={styles.imageContainer}>
      <img src={StarRatingImage.src} alt={formatMessage(StarRatingImage.alt)} />
    </div>
  ) : undefined;
  const previewState = {
    shouldDisplay: !isSMS || hasDPN,
    tooltip: formatMessage(
      isSMS ? messages.previewTooltipSMS : messages.previewTooltipEmail,
    ),
  };

  return (
    <div className={styles.previewSection}>
      <Flex template={["shrink", "shrink"]} direction="row" gap="small">
        <Heading level={5}>{formatMessage(messages.previewTitle)}</Heading>
        {previewState.shouldDisplay && (
          <Tooltip message={previewState.tooltip}>
            <Icon name="help" size="small" />
          </Tooltip>
        )}
      </Flex>
      {isSMS && staticTemplate && (
        <div className={styles.staticTemplate}>
          <TemplatePreviewer template={staticTemplate} showSmsArrow={false} />
        </div>
      )}
      <TemplatePreviewer template={template} staticImage={staticImage} />
    </div>
  );
}
