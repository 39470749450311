/* eslint-disable import/no-internal-modules */
import { Banner } from "@jobber/components/Banner";
import { Content } from "@jobber/components/Content";
import { Page } from "@jobber/components/Page";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import React, {
  type MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Heading } from "@jobber/components";
import { InlinePrompt } from "components/InlinePrompt";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import {
  JPayAutoenableTurnOffReasonModal,
  ctaName as jPayAutoenableTurnOffReasonModalCtaName,
} from "jobber/jPayAutoenable/components/JPayAutoenableTurnOffReasonModal";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";
import type {
  AchProcessingRate,
  ProcessingRates,
} from "~/jobber/managed_accounts/ProcessingRateBadges/types";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { useJobberPaymentsEnableDisable } from "~/utilities/hooks/useJobberPaymentsEnableDisable";
import { BankDetails, type BankDetailsProps } from "./BankDetails";
import { InstantPayoutDetails } from "./InstantPayoutDetails";
import { NotificationSettings } from "./Settings/NotificationSettingsProvider";
import { PaymentOptionsCard } from "./Settings/PaymentOptionCard";
import styles from "./JobberPaymentsSettings.module.css";
import { CardReaders, type CardReadersProps } from "./CardReaders";
import { ClientHubSettings } from "./Settings/ClientHubSettingsProvider";
import { PaymentsCard, type PaymentsCardProps } from "./PaymentsCard";
import { FinancialInsightsSection } from "./FinancialInsightsSection/FinancialInsightsSection";
import { messages } from "./messages";
import { JobberCapitalDetailsSection } from "./Settings/JobberCapitalDetailsSection";

export interface JobberPaymentsSettingsProps {
  bankDetailsProps: BankDetailsProps;
  processingRates: ProcessingRates;
  instantPayoutDebitCardInfo?: CreditCard;
  cardReaderProps: CardReadersProps;
  newSignUpSettingsEnabled: boolean;
  payoutsErrorMessage?: string;
  achProcessingRate?: AchProcessingRate;
  paymentCard: PaymentsCardProps;
  shouldShowJobberPaymentCard: boolean;
  shouldShowDisputesRow: boolean;
}

// eslint-disable-next-line max-statements
export function JobberPaymentsSettings(props: JobberPaymentsSettingsProps) {
  const {
    bankDetailsProps,
    newSignUpSettingsEnabled,
    paymentCard,
    shouldShowJobberPaymentCard,
    shouldShowDisputesRow,
  } = props;

  const { formatMessage } = useIntl();

  const jPayAutoenableReminderCtaRef =
    useRef() as MutableRefObject<CallToActionRef>;

  const [t] = useTranslation();
  const {
    enabled: globalEnabled,
    setEnabled: setGlobalEnabled,
    permissions,
    tipsEnabled,
  } = useJobberPayments();

  const [showJPayTurnOffReasonModal, setShowJPayTurnOffReasonModal] =
    useState(false);

  const { setJobberPaymentsEnabled } = useJobberPaymentsEnableDisable();

  const [isJobberPaymentsEnabled, setIsJobberPaymentsEnabled] =
    useState(globalEnabled);
  const [isJobberPaymentsLoading, setIsJobberPaymentsLoading] = useState(false);
  const [
    jobberPaymentsMutationErrorMessage,
    setJobberPaymentsMutationErrorMessage,
  ] = useState("");

  const handleJobberPaymentsToggle = async (enable: boolean) => {
    setIsJobberPaymentsLoading(true);
    setJobberPaymentsMutationErrorMessage("");
    try {
      await setJobberPaymentsEnabled(enable);
      setIsJobberPaymentsEnabled(enable);
      setGlobalEnabled(enable);

      if (!enable) {
        setShowJPayTurnOffReasonModal(true);
      }
    } catch (error) {
      setJobberPaymentsMutationErrorMessage(
        formatMessage(messages.defaultMutationErrorMessage) ||
          "Failed to update Jobber Payments state",
      );
    } finally {
      setIsJobberPaymentsLoading(false);
    }
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) {
      return;
    }

    Amplitude.TRACK_EVENT("Viewed Page", {
      name: "Jobber Payment Settings",
      action: "Viewed Jobber Payments Settings Page",
    });
    isFirstRender.current = false;
  });

  return (
    <div className={styles.pageWrapper}>
      {jobberPaymentsMutationErrorMessage && (
        <Banner
          type="error"
          onDismiss={() => setJobberPaymentsMutationErrorMessage("")}
        >
          {jobberPaymentsMutationErrorMessage}
        </Banner>
      )}
      <Page title={t("jobberPaymentsTitle")}>
        {!isJobberPaymentsEnabled && !newSignUpSettingsEnabled && (
          <Banner
            {...(permissions.canEnablePayments && {
              primaryAction: {
                label: "Turn On",
                onClick: () => handleJobberPaymentsToggle(true),
              },
            })}
            type="notice"
          >
            {permissions.canEnablePayments
              ? "Jobber Payments is currently turned off, turn it on to collect card payments"
              : "Jobber Payments is currently turned off, you'll need your account owner to turn it on to collect payments"}
          </Banner>
        )}

        <div className={styles.containerWithInsights}>
          <FinancialInsightsSection
            instantPayoutDebitCardInfo={props.instantPayoutDebitCardInfo}
            instantPayoutFeeMultiplier={paymentCard.instantPayoutFeeMultiplier}
          />

          <JPayDetailsSection
            bankDetailsProps={bankDetailsProps}
            instantPayoutDebitCardInfo={props.instantPayoutDebitCardInfo}
            paymentCard={paymentCard}
            shouldShowDisputesRow={shouldShowDisputesRow}
            shouldShowJobberPaymentCard={shouldShowJobberPaymentCard}
            canViewInstantPayouts={permissions.canViewInstantPayouts}
            showReportButtons={false}
          />

          <SettingsCardsSection
            tipsEnabled={tipsEnabled}
            cardReaderProps={props.cardReaderProps}
          />

          {isJobberPaymentsEnabled && permissions.canDisablePayments && (
            <section className={styles.disableJobberPaymentsContainer}>
              <Content spacing="base">
                <Heading level={4} element={"h3"}>
                  {formatMessage(messages.disableJobberPaymentsTitle)}
                </Heading>
                <div className={styles.disableJobberPaymentsDescription}>
                  <Text variation={"subdued"}>
                    {formatMessage(messages.disableJobberPaymentsDescription)}
                  </Text>
                </div>
                <Button
                  label={"Disable Jobber Payments"}
                  onClick={() => handleJobberPaymentsToggle(false)}
                  disabled={isJobberPaymentsLoading}
                  variation={"destructive"}
                  type={"secondary"}
                  size={"base"}
                  fullWidth={true}
                />
              </Content>
            </section>
          )}
          <section className={styles.jPayDisclosure}>
            <Text size="small" variation="subdued">
              {formatMessage(messages.jPayDisclosure)}
            </Text>
          </section>
        </div>
        {showJPayTurnOffReasonModal && (
          <APIProvider>
            <CallToAction
              ref={jPayAutoenableReminderCtaRef}
              ctaName={jPayAutoenableTurnOffReasonModalCtaName}
            >
              <JPayAutoenableTurnOffReasonModal
                dismissCTA={dismissCTA(jPayAutoenableReminderCtaRef)}
                convertCTA={convertCTA(jPayAutoenableReminderCtaRef)}
              />
            </CallToAction>
          </APIProvider>
        )}
      </Page>
    </div>
  );
}

interface SettingsCardsSectionProps
  extends Pick<JobberPaymentsSettingsProps, "cardReaderProps"> {
  tipsEnabled: boolean;
}
function SettingsCardsSection({
  tipsEnabled,
  cardReaderProps,
}: SettingsCardsSectionProps) {
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;
  const [toggleAchFlag, setToggleAchFlag] = useState(false);
  const [updateDefaultPreference, setUpdateDefaultPreference] = useState({
    preference: "",
    achEnabled: false,
  });
  const tipsCTAText = tipsEnabled
    ? "Your clients can now include a tip when paying invoices through client hub! To disable this, change your settings below."
    : "Want to receive tips from clients when they're paying invoices through client hub? Change your settings below to enable tip collection.";

  return (
    <Content spacing="large">
      <APIProvider>
        <CallToAction
          ctaName={"jobber_payments_settings_tips_helper"}
          ref={ctaRef}
        >
          <InlinePrompt
            title={tipsCTAText}
            handleDismiss={dismissCTA(ctaRef)}
          />
        </CallToAction>
      </APIProvider>

      <PaymentOptionsCard
        toggleAchSwitchFlag={toggleAchFlag}
        setUpdateDefaultPreference={setUpdateDefaultPreference}
      />

      <ClientHubSettings
        dismissCta={dismissCTA(ctaRef)}
        setToggleAchFlag={setToggleAchFlag}
        updateDefaultPreference={updateDefaultPreference}
      />

      <NotificationSettings />

      <CardReaders {...cardReaderProps} />
    </Content>
  );
}

interface JPayDetailsSectionProps
  extends Pick<
    JobberPaymentsSettingsProps,
    | "bankDetailsProps"
    | "instantPayoutDebitCardInfo"
    | "paymentCard"
    | "shouldShowDisputesRow"
    | "shouldShowJobberPaymentCard"
  > {
  canViewInstantPayouts: boolean;
  showReportButtons: boolean;
}
function JPayDetailsSection(props: JPayDetailsSectionProps) {
  const [instantPayoutDebitCardInfo, setInstantPayoutDebitCardInfo] = useState(
    props.instantPayoutDebitCardInfo,
  );

  const mergedPaymentCardProps = {
    showHeader: false,
    ...props.paymentCard,
  };

  const showJobberPaymentCard =
    props.shouldShowJobberPaymentCard ||
    (!props.shouldShowJobberPaymentCard && props.shouldShowDisputesRow);

  const [jobberPaymentsPayoutsPath, jobberPaymentsTransactionsPath] = useUrls(
    "jobberPaymentsPayoutsPath",
    "jobberPaymentsTransactionsPath",
  );

  return (
    <section className={styles.bankDetails}>
      <Content spacing="large">
        <BankDetails {...props.bankDetailsProps} />
        {props.canViewInstantPayouts && (
          <InstantPayoutDetails
            debitCard={instantPayoutDebitCardInfo}
            onDebitCardChange={setInstantPayoutDebitCardInfo}
            isJobberMoneyPayout={
              props.paymentCard.jobberMoney.payoutIsJobberMoneyAccount
            }
          />
        )}
        {showJobberPaymentCard && (
          <PaymentsCard
            {...mergedPaymentCardProps}
            shouldShowJobberPaymentCard={props.shouldShowJobberPaymentCard}
            showPayoutDetails={props.showReportButtons}
          />
        )}
        {props.showReportButtons && (
          <Content spacing="small">
            <Button
              label="View bank payouts"
              type="primary"
              url={jobberPaymentsPayoutsPath}
              fullWidth={true}
            />
            <Button
              label="View transactions"
              type="secondary"
              url={jobberPaymentsTransactionsPath}
              fullWidth={true}
            />
          </Content>
        )}
        <JobberCapitalDetailsSection />
      </Content>
    </section>
  );
}
