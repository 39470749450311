import React, { useState } from "react";
import { Button } from "@jobber/components/Button";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { IntlProvider } from "@translations/IntlProvider";
import { calculateMarkup } from "jobber/workItems/utils";
import type { WorkItem } from "jobber/workItems/types";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import type { LineItem } from "~/jobber/lineItems/types";
import { WorkItemModal } from "jobber/workItems/components/WorkItemModal";
import styles from "./AddNewProductOrServicePromptStyles.module.css";
import { messages } from "./messages";

export interface WorkItemInputProps {
  name?: string;
  description?: string;
  unitPrice?: number;
  unitCost?: number;
  markup?: number;
  category?: "PRODUCT" | "SERVICE";
}

export interface AddNewProductOrServicePromptProps {
  lineItem: LineItem;
  currencySymbol: string;
  show: boolean;
  onSave(lineItem: WorkItem): void;
  canViewProductsAndServices: boolean;
}

function AddNewProductOrServicePromptInternal(
  props: AddNewProductOrServicePromptProps,
) {
  const { formatMessage } = useIntl();
  const [modalOpen, setModalOpen] = useState(false);
  const account = useAccount();
  const quoteMarginsEnabled = account.features.quoteMargins.enabled;
  const quoteTemplatesEnabled = account.features.quoteTemplates.enabled;

  if (!props.canViewProductsAndServices) return <></>;

  return (
    <div
      className={styles.addProductOrServicePrompt}
      style={{ visibility: props.show ? "visible" : "hidden" }}
    >
      <Text>This is a custom line item</Text>
      <WorkItemModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        modalTitle={formatMessage(messages.addNewProductService)}
        workItem={translateInputWorkItemProps({
          name: props.lineItem.name,
          description: props.lineItem.description,
          unitPrice: props.lineItem.unitPrice,
          unitCost: props.lineItem.unitCost,
        })}
        onSaved={quoteLineItem => {
          props.onSave(quoteLineItem);
          closeModal();
        }}
        currencySymbol={props.currencySymbol}
        quoteMarginsEnabled={quoteMarginsEnabled}
        selfServeBookingsEnabled={false}
        advancedQuotingEnabled={false}
        quoteTemplatesEnabled={quoteTemplatesEnabled}
      />

      <Button
        label="Add to Products & Services"
        size="small"
        onClick={openModal}
      />
    </div>
  );

  function openModal() {
    setModalOpen(true);
  }
  function closeModal() {
    setModalOpen(false);
  }
}

function translateInputWorkItemProps(
  initialWorkItem: WorkItemInputProps,
): WorkItem {
  return {
    name: initialWorkItem.name || "",
    description: initialWorkItem.description,
    defaultUnitCost: initialWorkItem.unitPrice || 0,
    internalUnitCost: initialWorkItem.unitCost,
    category: initialWorkItem.category || "SERVICE",
    markup:
      initialWorkItem.markup ||
      calculateMarkup(initialWorkItem.unitPrice, initialWorkItem.unitCost),
    taxable: true,
  };
}

export function AddNewProductOrServicePrompt(
  props: AddNewProductOrServicePromptProps,
) {
  return (
    <IntlProvider>
      <AddNewProductOrServicePromptInternal {...props} />
    </IntlProvider>
  );
}
