import { useAuth } from "~/utilities/contexts/internal/useAuth";

export function useJobberPaymentsEnableDisable() {
  const [authenticityToken] = useAuth();

  async function setJobberPaymentsEnabled(enable: boolean) {
    const response = await fetch("/jobber_payments/set_managed_account_state", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": authenticityToken,
      },
      credentials: "include",
      body: JSON.stringify({
        enable,
        authenticity_token: authenticityToken,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to update Jobber Payments state");
    }

    return response;
  }

  return { setJobberPaymentsEnabled };
}
