import React, { useEffect, useRef, useState } from "react";
import { Content, Tab, Tabs } from "@jobber/components";
import { useIntl } from "react-intl";
import { TemplateEditor } from "~/components/NotificationTemplates/TemplateEditor/TemplateEditor";
import type { MessageTemplate } from "~/components/NotificationTemplates/types";
import { MessagePreviewer } from "jobber/reviews/views/ReviewsPage/components/ReviewsSettings/ReviewMessagePreviewer/ReviewMessagePreviewer";
import type {
  ReviewMessageCustomizerProps,
  TabContentProps,
  TabsProps,
} from "./types";
import { messages } from "./messages";
import styles from "./ReviewMessageCustomizer.module.css";

export function ReviewMessageCustomizer({
  deliveryMethod,
  dynamicTemplates,
  staticTemplate,
}: ReviewMessageCustomizerProps): JSX.Element {
  const [templatesToDisplay, setTemplatesToDisplay] =
    useState<MessageTemplate[]>(dynamicTemplates);
  const templateStoreRef = useRef(dynamicTemplates);

  const handleTemplateChange = (newTemplate: MessageTemplate): void => {
    const newTemplates = templatesToDisplay.map(template => {
      if (template.id === newTemplate.id) {
        return newTemplate;
      }
      return template;
    });

    const index = templateStoreRef.current.findIndex(
      template => template.id === newTemplate.id,
    );
    templateStoreRef.current[index] = newTemplate;

    setTemplatesToDisplay([...newTemplates]);
  };

  useEffect(() => {
    if (deliveryMethod === "SMS") {
      const smsTemplate = templateStoreRef.current.find(
        template => template.deliveryMethod === "SMS",
      );
      setTemplatesToDisplay(
        smsTemplate !== undefined ? [smsTemplate] : templateStoreRef.current,
      );
    } else if (deliveryMethod === "EMAIL") {
      const emailTemplate = templateStoreRef.current.find(
        template => template.deliveryMethod === "EMAIL",
      );
      setTemplatesToDisplay(
        emailTemplate !== undefined
          ? [emailTemplate]
          : templateStoreRef.current,
      );
    } else {
      setTemplatesToDisplay(templateStoreRef.current);
    }
  }, [deliveryMethod]);

  return (
    <TextAndEmailCustomizer
      dynamicTemplates={templatesToDisplay}
      staticTemplate={staticTemplate}
      handleTemplateChange={handleTemplateChange}
    />
  );
}

function TextAndEmailCustomizer({
  dynamicTemplates,
  staticTemplate,
  handleTemplateChange,
}: TabsProps) {
  const { formatMessage } = useIntl();

  return (
    <Tabs defaultTab={dynamicTemplates.length > 1 ? 1 : 0}>
      {dynamicTemplates.map(template => (
        <Tab
          key={template.id}
          label={
            template.deliveryMethod === "EMAIL"
              ? formatMessage(messages.emailTabTitle)
              : formatMessage(messages.textMessageTabTitle)
          }
        >
          <TabContent
            template={template}
            staticTemplate={staticTemplate}
            handleTemplateChange={handleTemplateChange}
          />
        </Tab>
      ))}
    </Tabs>
  );
}

function TabContent({
  template,
  staticTemplate,
  handleTemplateChange,
}: TabContentProps) {
  return (
    <Content>
      <TemplateEditor
        template={template}
        setTemplate={newTemplate => {
          handleTemplateChange(newTemplate);
        }}
        inputRowMin={6}
        inputRowMax={6}
      />
      <div className={styles.previewSection}>
        <MessagePreviewer
          template={template}
          staticTemplate={staticTemplate}
          hasDPN={true}
        />
      </div>
    </Content>
  );
}
