import React from "react";
import { Box } from "@jobber/components/Box";
import { Grid } from "@jobber/components/Grid";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { Divider } from "@jobber/components/Divider";
import { Flex } from "@jobber/components/Flex";
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import { useIntl } from "react-intl";
import forceFleetTrackingConnectImg from "./force-flee-tracking-connect.png";
import { messages } from "./messages";

interface SignupConnectContentProps {
  onGetStarted: () => void;
  onConnect: () => void;
}

function StyledDivider({ text }: { text: string }) {
  return (
    <Flex template={["grow", "shrink", "grow"]} gap="small">
      <Divider />
      <Typography
        element="span"
        fontWeight="bold"
        size="small"
        textCase="uppercase"
        textColor="textSecondary"
      >
        {text}
      </Typography>
      <Divider />
    </Flex>
  );
}

export function SignupConnectContent({
  onGetStarted,
  onConnect,
}: SignupConnectContentProps) {
  const { formatMessage } = useIntl();
  return (
    <Grid alignItems="center" gap>
      <Grid.Cell size={{ xs: 6 }}>
        <Box gap="large" margin={{ right: "largest" }}>
          <Box gap="large">
            <Box gap="base">
              <Heading level={2} element="span">
                {formatMessage(messages.signUpOrConnectContentSignUpHeading)}
              </Heading>
              <Text>
                {formatMessage(
                  messages.signUpOrConnectContentSignUpDescription,
                )}
              </Text>
            </Box>
            <Button label="Get Started" onClick={onGetStarted} />
          </Box>
          <StyledDivider text="OR" />
          <Box gap="large">
            <Box gap="base">
              <Heading level={4} element="span">
                {formatMessage(messages.signUpOrConnectContentConnectHeading)}
              </Heading>
              <Text>
                {formatMessage(
                  messages.signUpOrConnectContentConnectDescription,
                )}
              </Text>
            </Box>
            <Button
              label="Connect"
              type="secondary"
              variation="learning"
              onClick={onConnect}
            />
          </Box>
        </Box>
      </Grid.Cell>
      <Grid.Cell size={{ xs: 6 }}>
        <img
          src={forceFleetTrackingConnectImg}
          alt={formatMessage(messages.signUpOrConnectContentImageAlt)}
          style={{ minHeight: "395px" }}
        />
      </Grid.Cell>
    </Grid>
  );
}
