import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { IntlProvider } from "@translations/IntlProvider";
import type { SubscriptionAddonPreview } from "~/shared/billing/pricePreview/types";
import { APIProvider } from "~/utilities/API/APIProvider";
import type {
  BillingCycleName,
  CheckoutAccountAddonsInfoQuery,
  CheckoutAccountAddonsInfoQueryVariables,
} from "~/utilities/API/graphql";
import { Rollbar } from "~/utilities/errors/Rollbar";
import { withSplitClient } from "utilities/split";
import { CHECKOUT_ACCOUNT_ADDONS_INFO } from "./Checkout.graphql";
import { Checkout } from "./Checkout";
import { addonBeta, addonRecommended } from "./utils";

interface CheckoutLoaderProps {
  recurlyPublicKey: string;
  planSetIdentifier: string;
  billingCycleName: BillingCycleName;
  preselectedAddons?: string[];
}

export const CheckoutLoader = withSplitClient(WrappedCheckoutLoader);

function WrappedCheckoutLoader(checkoutLoaderProps: CheckoutLoaderProps) {
  return (
    <IntlProvider>
      <APIProvider>
        <InternalCheckoutLoader {...checkoutLoaderProps} />
      </APIProvider>
    </IntlProvider>
  );
}

function InternalCheckoutLoader({
  recurlyPublicKey,
  planSetIdentifier,
  billingCycleName,
  preselectedAddons,
}: CheckoutLoaderProps) {
  const [subscriptionAddons, setSubscriptionAddons] = useState<
    SubscriptionAddonPreview[]
  >([]);

  const { loading: loadingAddons } = useQuery<
    CheckoutAccountAddonsInfoQuery,
    CheckoutAccountAddonsInfoQueryVariables
  >(CHECKOUT_ACCOUNT_ADDONS_INFO, {
    variables: { planSetIdentifier },
    onCompleted: data => setSubscriptionAddons(filterAddons(data)),
    onError: error => {
      Rollbar.EXECUTE(
        `Unable to fetch add-ons for plan set identifier: ${planSetIdentifier}`,
        new Error(`CheckoutLoader: ${error.message}`),
      );
    },
  });

  return (
    <Checkout
      recurlyPublicKey={recurlyPublicKey}
      planSetIdentifier={planSetIdentifier}
      billingCycleName={billingCycleName}
      subscriptionAddons={subscriptionAddons}
      loadingAddons={loadingAddons}
      preselectedAddons={preselectedAddons}
    />
  );
}

export function filterAddons(
  data: CheckoutAccountAddonsInfoQuery,
): SubscriptionAddonPreview[] {
  const addonsData = data?.accountAddonsInfo?.addons || [];

  return addonsData.reduce((acc: SubscriptionAddonPreview[], addon) => {
    if (
      !addonBeta(addon.baseIdentifier) &&
      !addon.isActive &&
      addon.previewGroup?.monthlyPreview
    ) {
      acc.push({
        name: addon.name,
        recommended: addonRecommended(addon.identifier),
        isActive: addon.isActive,
        identifier: addon.identifier,
        baseIdentifier: addon.baseIdentifier,
        includedAddonSetIdentifiers: addon.includedAddonSetIdentifiers,
        includedWithAddonSetIdentifiers: addon.includedWithAddonSetIdentifiers,
        currency: addon.currency,
        monthlyBillingCycle: {
          addonCode: addon.monthlyBillingCycle.addonCode,
          monthlyCost: addon.monthlyBillingCycle.monthlyCost,
        },
        discountGroup: addon.discountGroup,
        previewGroup: {
          monthlyPreview: addon.previewGroup.monthlyPreview,
        },
      });
    }
    return acc;
  }, []);
}
