import { gql } from "@apollo/client";

const AI_ASSISTANT_MESSAGE_DATA = gql`
  fragment AiAssistantMessageData on AiAssistantMessage {
    conversationId
    requestId
    createdAt
    content {
      ... on AiAssistantLoadRequest {
        includeAccount
      }
      ... on AiAssistantLoadResponse {
        accountIncluded
        errors
      }
      ... on AiAssistantPromptRequest {
        prompt
      }
      ... on AiAssistantReviewReplyResponse {
        response
      }
      ... on AiAssistantPromptResponse {
        response
        footnotes
        explanation {
          ... on AiAssistantQuestionInterpretation {
            body
            boilerplate
          }
        }
        visuals {
          ... on AiAssistantImage {
            altText
            imageUrl
            title
            supportingLinkUrl
          }
          ... on AiAssistantSimplePlot {
            chartType
            dataPoints
            title
            xAxisLabel
            xCategories
            yAxisLabel
          }
          ... on AiAssistantVideo {
            url
            title
            summary
          }
        }
        followups {
          displayText
          prompt
        }
        errors
        actions {
          metadata
          intent
          followups {
            displayText
            prompt
            metadata
          }
        }
        referencedObjects {
          encodedId
          id
          textRepresentation
          type
        }
      }
      ... on AiAssistantPartialResponse {
        response
      }
      ... on AiAssistantTextReviseExchange {
        submission
        operation
        revision
        contentType
        errors
      }
    }
  }
`;

export const AI_ASSISTANT_LAST_CONVERSATION = gql`
  query AiAssistantConversations {
    aiAssistantConversations(
      sort: { key: CREATED_AT, direction: DESCENDING }
      first: 1
    ) {
      nodes {
        conversationId
        createdAt
        messages(sort: { key: CREATED_AT, direction: DESCENDING }) {
          nodes {
            ...AiAssistantMessageData
          }
        }
      }
    }
  }
  ${AI_ASSISTANT_MESSAGE_DATA}
`;

export const AI_ASSISTANT_LOAD = gql`
  mutation AiAssistantLoad(
    $conversationId: Uuid!
    $input: AiAssistantLoadInput!
    $requestId: Uuid!
  ) {
    aiAssistantLoad(
      conversationId: $conversationId
      input: $input
      requestId: $requestId
    ) {
      userErrors {
        message
        path
      }
    }
  }
`;

export const AI_ASSISTANT_SUBSCRIPTION = gql`
  subscription AiAssistantSubscription($conversationId: Uuid!) {
    aiAssistantMessages(conversationId: $conversationId) {
      ...AiAssistantMessageData
    }
  }
  ${AI_ASSISTANT_MESSAGE_DATA}
`;

export const AI_ASSISTANT_SEND_PROMPT = gql`
  mutation AiAssistantSendPrompt(
    $conversationId: Uuid!
    $requestId: Uuid!
    $input: AiAssistantPromptInput!
  ) {
    aiAssistantPrompt(
      conversationId: $conversationId
      input: $input
      requestId: $requestId
    ) {
      userErrors {
        message
        path
      }
    }
  }
`;

export const AI_ASSISTANT_CREATE_FEEDBACK = gql`
  mutation AiAssistantFeedbackCreate(
    $conversationId: Uuid
    $requestId: Uuid
    $input: AiAssistantFeedbackCreateInput!
  ) {
    aiAssistantFeedbackCreate(
      conversationId: $conversationId
      requestId: $requestId
      input: $input
    ) {
      userErrors {
        message
        path
      }
    }
  }
`;

export const AI_ASSISTANT_CONTEXT_HAND_OFF = gql`
  mutation AiAssistantContextHandOff(
    $requestId: Uuid!
    $input: AiAssistantContextHandOffInput!
  ) {
    aiAssistantContextHandOff(requestId: $requestId, input: $input) {
      userErrors {
        message
        path
      }
    }
  }
`;

export const AI_ASSISTANT_SUPPORT_ESCALATION_INTERACTION_UPDATE = gql`
  mutation AiAssistantSupportEscalationInteractionUpdate(
    $requestId: Uuid!
    $input: AiAssistantSupportEscalationInteractionUpdateInput!
  ) {
    aiAssistantSupportEscalationInteractionUpdate(
      requestId: $requestId
      input: $input
    ) {
      userErrors {
        message
        path
      }
    }
  }
`;
