import React, { type MutableRefObject, useRef, useState } from "react";
import { Popover } from "@jobber/components/Popover";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import classNames from "classnames";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
  trackAnalytics,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import styles from "./CoreToConnectTrialHomePopovers.module.css";
import { CoreToConnectTrialOnboardingMobileCard } from "../CoreToConnectTrialOnboardingMobileCard";

export function CoreToConnectTrialHomePopovers() {
  const [popover, setPopover] = useState(0);

  const ctaName = "core_to_connect_trial_home_onboarding_cta";
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;

  const popoverContent = [
    {
      elementSelector: `[aria-label="Expenses"]`,
      heading: "Expense Tracking",
      content:
        "Give every member of your team the ability to log their expenses so you can track exactly how much money was spent on each job.",
      primaryAction: handleNextPopover,
    },
    {
      elementSelector: `[aria-label="Timesheets"]`,
      heading: "Timesheets",
      content:
        "Track your hours and the hours of your employees in one easy location.",
      primaryAction: handleNextPopover,
    },
    {
      elementSelector: `[aria-label="Open Settings Menu"]`,
      heading: "Settings",
      content:
        "Discover additional features that help you build efficient processes in the Settings page.",
      primaryAction: convertCTA(ctaRef, { type: `popover_step_3` }),
      buttonLabel: "Got it",
    },
  ];
  return (
    <APIProvider>
      <CallToAction ctaName={ctaName} ref={ctaRef}>
        <div>
          <CoreToConnectTrialOnboardingMobileCard />
          <div className={classNames(styles.popoverWrapper)}>
            <CoreToConnectTrialPopover
              elementSelector={popoverContent[popover].elementSelector}
              heading={popoverContent[popover].heading}
              content={popoverContent[popover].content}
              popoverIndex={popover + 1}
              buttonLabel={popoverContent[popover].buttonLabel}
              primaryAction={popoverContent[popover].primaryAction}
              dismissPopover={dismissCTA(ctaRef, {
                type: `popover_step_${popover + 1}`,
              })}
            />
          </div>
        </div>
      </CallToAction>
    </APIProvider>
  );

  function handleNextPopover() {
    setPopover(popover + 1);
    trackAnalytics("CTA Clicked", ctaName, {
      type: `popover_step_${popover + 1}`,
    });
  }
}

interface CoreToConnectTrialPopoverProps {
  elementSelector: string;
  heading: string;
  content: string;
  popoverIndex: number;
  buttonLabel?: string;
  primaryAction(): void;
  dismissPopover(): void;
}

function CoreToConnectTrialPopover({
  elementSelector,
  heading,
  content,
  popoverIndex,
  buttonLabel = "Next",
  primaryAction,
  dismissPopover,
}: CoreToConnectTrialPopoverProps) {
  const element = findElement(elementSelector);

  return element ? (
    <Popover
      UNSAFE_className={{
        container:
          popoverIndex <= 2
            ? styles.popoverWrapperForNavBar
            : popoverIndex === 3
              ? styles.popoverWrapperForSettings
              : undefined,
      }}
      attachTo={element}
      open={true}
      preferredPlacement="right"
      onRequestClose={dismissPopover}
    >
      <Content>
        <Heading level={5}>{heading}</Heading>
        <Text size="small">{content}</Text>
        <div className={styles.popoverButtonContainer}>
          <Text size="small" variation="subdued">
            {popoverIndex} of 3
          </Text>
          <Button
            variation="learning"
            label={buttonLabel}
            onClick={primaryAction}
          />
        </div>
      </Content>
    </Popover>
  ) : (
    <></>
  );
}

function findElement(elementSelector: string) {
  const elements = $(elementSelector);
  if (elements) {
    return elements[0];
  }
}
