import React, { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { Banner, Option, Select } from "@jobber/components";
import { showToast } from "@jobber/components/Toast";
import { LoadingLayout } from "jobber/reviews/views/ReviewsPage/components/ReviewsSettings/LoadingLayout/LoadingLayout";
import type {
  MessageTemplateEmail,
  MessageTemplateSms,
} from "~/utilities/API/graphql";
import { ClientNotificationId, DeliveryMethod } from "~/utilities/API/graphql";
import { ReviewMessageCustomizer } from "jobber/reviews/views/ReviewsPage/components/ReviewsSettings/ReviewMessageCustomizer/ReviewMessageCustomizer";
import {
  DrawerView,
  ReviewsSettingsDrawerContext,
} from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import { ReviewMessagePreviewer } from "jobber/reviews/views/ReviewsPage/components/ReviewsSettings/ReviewMessagePreviewer/ReviewMessagePreviewer";
import { PrivacyMask } from "components/Observability/PrivacyMask";
import { useUpdateNotification } from "jobber/reviews/views/ReviewsPage/hooks/useUpdateNotification";
import {
  FormProvider,
  type FormSubmitHandler,
  type SubmitHandler,
  useFormWithDefaults,
} from "~/utilities/reactHookForm/module";
import { useEditReviewsInitialMessageSettings } from "jobber/reviews/hooks/useEditReviewsInitialMessageSettings";
import { useInitialMessageQuery } from "jobber/reviews/views/ReviewsPage/components/ReviewsSettings/InitialMessageSettings/useInitialMessageQuery/useInitialMessageQuery";
import { messages } from "../messages";
import styles from "../ReviewsSettings.module.css";

interface InitialMessageSettingsProps {
  showCustomizations: boolean;
}

interface FormData {
  deliveryMethod: DeliveryMethod;
  emailSubject: string;
  emailMessage: string;
  smsMessage: string;
}

export function InitialMessageSettings({
  showCustomizations,
}: InitialMessageSettingsProps) {
  const { formatMessage } = useIntl();
  const { drawerActions, form, showInsights, unsavedChangesModalActions } =
    useContext(ReviewsSettingsDrawerContext);
  const { dynamicTemplates, staticTemplates, loading, fetchMethodError } =
    useInitialMessageQuery(showInsights);
  const templates = [dynamicTemplates?.email, dynamicTemplates?.sms];

  const { updateNotification } = useUpdateNotification();
  const { editReviewsInitialMessageSettings } =
    useEditReviewsInitialMessageSettings();

  const defaultValues = {
    deliveryMethod:
      dynamicTemplates?.deliveryMethod || DeliveryMethod.EMAIL_AND_SMS,
    emailSubject: dynamicTemplates?.email?.subject?.current || "",
    emailMessage: dynamicTemplates?.email?.message?.current || "",
    smsMessage: dynamicTemplates?.sms?.message?.current || "",
  };

  const callEditMutation = async (deliveryMethod: DeliveryMethod) => {
    const editReviewsInitialMessageSettingsErrors =
      await editReviewsInitialMessageSettings({ deliveryMethod });

    return editReviewsInitialMessageSettingsErrors.length > 0
      ? editReviewsInitialMessageSettingsErrors
      : [];
  };

  const updateFormIsDirty = (isDirty: boolean) => {
    form?.setIsDirty(isDirty);
  };

  return (
    <PrivacyMask disabled>
      <div className={styles.backButton}>
        <Button
          type="tertiary"
          variation="subtle"
          icon="longArrowLeft"
          ariaLabel={formatMessage(messages.messageSettingsBackAriaLabel)}
          onClick={() => {
            if (form?.isDirty) {
              unsavedChangesModalActions?.open();
            } else {
              drawerActions?.goTo(DrawerView.ManageSettings);
            }
          }}
        />
      </div>

      <div className={styles.settingsContainer}>
        {fetchMethodError && (
          <Banner type={"error"} dismissible={true}>
            {formatMessage(messages.deliveryMethodError)}
          </Banner>
        )}

        {loading ? (
          <LoadingLayout showCustomizations={showCustomizations} />
        ) : (
          <SettingsForm
            defaultValues={defaultValues}
            showCustomizations={showCustomizations}
            templates={templates}
            staticTemplate={staticTemplates}
            updateFormIsDirty={updateFormIsDirty}
            // eslint-disable-next-line max-statements
            onSubmit={async (
              { deliveryMethod, emailSubject, emailMessage, smsMessage },
              { setError },
            ) => {
              let errors: string[] = [];

              if (
                emailSubject !== defaultValues.emailSubject ||
                emailMessage !== defaultValues.emailMessage ||
                showInsights
              ) {
                const updateNotificationEmailErrors = await updateNotification({
                  clientNotificationId: ClientNotificationId.REVIEW_REQUEST,
                  templateId: dynamicTemplates.email.id,
                  subject: emailSubject,
                  message: emailMessage,
                });

                errors =
                  updateNotificationEmailErrors.length > 0
                    ? errors.concat(updateNotificationEmailErrors)
                    : errors;
              }

              if (smsMessage !== defaultValues.smsMessage || showInsights) {
                const updateNotificationSmsErrors = await updateNotification({
                  clientNotificationId: ClientNotificationId.REVIEW_V2_REQUEST,
                  templateId: dynamicTemplates.sms.id,
                  message: smsMessage,
                });

                errors =
                  updateNotificationSmsErrors.length > 0
                    ? errors.concat(updateNotificationSmsErrors)
                    : errors;
              }

              if (deliveryMethod !== defaultValues.deliveryMethod) {
                const mutationErrors: string[] =
                  await callEditMutation(deliveryMethod);
                errors = errors.concat(mutationErrors);
              }

              if (errors.length > 0) {
                setError("root.serverError", {
                  type: "serverError",
                  message: formatMessage(messages.generalError),
                });
              } else {
                updateFormIsDirty(false);
                showToast({
                  message: formatMessage(messages.messageSettingsSaveSuccess),
                });
                drawerActions?.goTo(DrawerView.ManageSettings);
              }
            }}
          />
        )}
      </div>
    </PrivacyMask>
  );
}

interface SettingsFormProps {
  defaultValues: FormData;
  showCustomizations: boolean;
  templates: (MessageTemplateEmail | MessageTemplateSms)[];
  staticTemplate: MessageTemplateSms;
  updateFormIsDirty: (isDirty: boolean) => void;
  onSubmit: FormSubmitHandler<FormData>;
}

function SettingsForm({
  defaultValues,
  showCustomizations,
  templates,
  staticTemplate,
  updateFormIsDirty,
  onSubmit,
}: SettingsFormProps) {
  const { formatMessage } = useIntl();

  const methods = useFormWithDefaults<FormData>({ defaultValues });

  const [deliveryMethod] = methods.watch(["deliveryMethod"]);

  const handleSubmit: SubmitHandler<FormData> = formValues => {
    onSubmit(formValues, { setError: methods.setError });
  };

  useEffect(() => {
    updateFormIsDirty(methods.formState.isDirty);
    // updateFormIsDirty is not included as a dependency to avoid redundant setIsDirty calls when it is redeclared on renders
  }, [methods.formState.isDirty]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {methods.formState.errors?.root?.serverError && (
        <Banner type={"error"} dismissible={true}>
          {formatMessage(messages.generalError)}
        </Banner>
      )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Select
            name={"deliveryMethod"}
            placeholder={formatMessage(
              messages.messageSettingsDeliveryPlaceholder,
            )}
          >
            <Option value={DeliveryMethod.EMAIL_AND_SMS}>
              {formatMessage(messages.bothOptionsLabel)}
            </Option>
            <Option value={DeliveryMethod.EMAIL}>
              {formatMessage(messages.emailOptionLabel)}
            </Option>
            <Option value={DeliveryMethod.SMS}>
              {formatMessage(messages.textOptionLabel)}
            </Option>
          </Select>

          <div className={styles.editContainer}>
            {showCustomizations ? (
              <ReviewMessageCustomizer
                deliveryMethod={deliveryMethod}
                dynamicTemplates={templates}
                staticTemplate={staticTemplate}
              />
            ) : (
              <ReviewMessagePreviewer
                deliveryMethod={deliveryMethod}
                templates={templates}
                staticTemplate={staticTemplate}
              />
            )}
          </div>

          <div className={styles.footer}>
            <Button
              type="primary"
              size="large"
              submit={true}
              fullWidth={true}
              ariaLabel={formatMessage(messages.messageSettingsSaveAriaLabel)}
              label={formatMessage(messages.messageSettingsSaveLabel)}
              disabled={methods.formState.isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </>
  );
}
