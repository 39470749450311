import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { communityForum } from "jobber/setupWizard/components/constants";
import {
  communityStep,
  defaultSteps,
  promotionStep,
  themeStep,
} from "jobber/setupWizard/stepDefinitions";
import { Context } from "jobber/setupWizard/context/SetupWizardContext";
import { Routes } from "../types";
import type { SetupWizardStep } from "../types";

export function useSetupWizardSteps(
  showPromo: boolean,
  attrName: string,
  isMobileDevice: boolean,
) {
  const routerLocation = useLocation();
  const history = useHistory();
  const [currentSteps, setCurrentSteps] = useState<SetupWizardStep[]>([]);
  const { ctas } = useContext(Context);

  const calculateCurrentSteps = useCallback(() => {
    let calculatedSteps = [...defaultSteps];

    if (isMobileDevice) {
      calculatedSteps = calculatedSteps.filter(
        step => step.route !== themeStep.route,
      );
    }

    if (attrName === communityForum) {
      calculatedSteps = [...communityStep];
    } else if (showPromo) {
      calculatedSteps.push(promotionStep);
    }

    if (ctas.progressiveOnboarding) {
      return calculatedSteps.filter(
        step =>
          ![Routes.topPriority, Routes.theme, Routes.promotion].includes(
            step.route,
          ),
      );
    }

    return calculatedSteps;
  }, [attrName, showPromo, isMobileDevice, ctas.progressiveOnboarding]);

  useEffect(() => {
    setCurrentSteps(calculateCurrentSteps());
  }, [calculateCurrentSteps]);

  const currentStepIndex = currentSteps
    .map(step => step.route.toString())
    .indexOf(routerLocation.pathname);

  const [showProgressBar, setShowProgressBar] = useState<boolean>(true);
  useEffect(() => {
    setShowProgressBar(
      currentSteps[currentStepIndex]?.includeInProgress === true,
    );
  }, [currentSteps, currentStepIndex]);

  const onNext = useCallback(() => {
    if (currentStepIndex === currentSteps.length - 1) {
      window.location.reload();
    } else {
      const nextStep = currentSteps[currentStepIndex + 1].route;
      history.push(nextStep.toString());
    }
  }, [currentStepIndex, currentSteps, history]);

  return {
    isLoading: currentSteps.length === 0,
    currentSteps,
    currentStepIndex,
    showProgressBar,
    onNext,
  };
}
