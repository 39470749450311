import React, { useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { Box } from "@jobber/components/Box";
import { NewScheduleInfoContent } from "./NewScheduleInfoContent";
import { SignupConnectContent } from "./SignupConnectContent";

interface SignupOrConnectModalState {
  view: "signupOrConnect" | "newScheduleInfo";
  pendingAction: "connect" | "getStarted" | null;
}

interface GpsTrackingSignupOrConnectModalProps {
  open: boolean;
  onConnect: () => void;
  onRequestClose: () => void;
  onGetStarted: () => void;
  hasNewScheduleEnabled?: boolean;
  app: { id: string; name: string };
}

export function GpsTrackingSignupOrConnectModal({
  open,
  onConnect,
  onRequestClose,
  onGetStarted,
  hasNewScheduleEnabled = false,
  app,
}: GpsTrackingSignupOrConnectModalProps) {
  const [state, setState] = useState<SignupOrConnectModalState>({
    view: "signupOrConnect",
    pendingAction: null,
  });

  const handleAction = (action: "connect" | "getStarted") => {
    if (!hasNewScheduleEnabled) {
      setState({
        view: "newScheduleInfo",
        pendingAction: action,
      });
      return;
    }

    if (action === "connect") {
      onConnect();
    } else {
      onGetStarted();
    }
  };

  const handleContinue = () => {
    if (state.pendingAction === "connect") {
      onConnect();
    } else if (state.pendingAction === "getStarted") {
      onGetStarted();
    }
    setState({ view: "signupOrConnect", pendingAction: null });
    onRequestClose();
  };

  const handleCancel = () => {
    setState({ view: "signupOrConnect", pendingAction: null });
    onRequestClose();
  };

  return (
    <Modal
      open={open}
      size="large"
      dismissible={true}
      title=""
      onRequestClose={handleCancel}
    >
      <Box padding={{ horizontal: "extravagant", bottom: "extravagant" }}>
        {state.view === "signupOrConnect" ? (
          <SignupConnectContent
            onGetStarted={() => handleAction("getStarted")}
            onConnect={() => handleAction("connect")}
          />
        ) : (
          <NewScheduleInfoContent
            app={app}
            onContinue={handleContinue}
            onCancel={handleCancel}
          />
        )}
      </Box>
    </Modal>
  );
}
