import React from "react";
import { Banner } from "@jobber/components";
import { useIntl } from "react-intl";
import styles from "./PurchaseRequirementsBanner.module.css";
import { messages } from "./messages";

export function PurchaseRequirementsBanner() {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.bannerContainer}>
      <Banner
        type={"notice"}
        primaryAction={{
          variation: "work",
          type: "secondary",
          label: formatMessage(messages.setUp),
          ariaLabel: formatMessage(messages.setUpAriaLabel),
          url: "/text_messaging_settings",
        }}
        dismissible={false}
      >
        {formatMessage(messages.dpnMissingDescription)}
      </Banner>
    </div>
  );
}
