import { Gallery } from "@jobber/components/Gallery";
import React from "react";

interface CostingGalleryProps {
  name: string;
  type: string;
  size: number;
  src: string;
  progress: number;
}

// This component was only required because the Atlantis
// Gallery Component doesn't prevent click event propagation
// https://jobber.atlassian.net/browse/JOB-67601
export function CostingGallery({
  name,
  type,
  size,
  src,
  progress,
}: CostingGalleryProps) {
  const preventClickPropagation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div
      onClick={preventClickPropagation}
      data-testid="gallery-wrapper"
      role="presentation"
    >
      <Gallery
        files={[
          {
            name,
            type,
            size,
            src,
            progress,
            key: `${name}_${size}`,
          },
        ]}
      />
    </div>
  );
}
