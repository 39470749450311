import { useMutation } from "@apollo/client";
import type {
  DeliveryMethod,
  ReviewsInitialMessageSettingsEditMutation,
} from "~/utilities/API/graphql";
import { REVIEWS_INITIAL_MESSAGE_SETTINGS_EDIT_MUTATION } from "../reviews.graphql";

export interface EditReviewsInitialMessageSettingsArgs {
  deliveryMethod?: keyof typeof DeliveryMethod;
}

export function useEditReviewsInitialMessageSettings() {
  const [editMutation] = useMutation<ReviewsInitialMessageSettingsEditMutation>(
    REVIEWS_INITIAL_MESSAGE_SETTINGS_EDIT_MUTATION,
  );

  async function editReviewsInitialMessageSettings(
    args: EditReviewsInitialMessageSettingsArgs,
  ) {
    const { deliveryMethod } = args;

    try {
      const result = await editMutation({
        variables: {
          input: {
            deliveryMethod: deliveryMethod?.toUpperCase(),
          },
        },
      });

      return result.data?.reviewsInitialMessageSettingsEdit.userErrors ?? [];
    } catch (e) {
      return [e];
    }
  }

  return {
    editReviewsInitialMessageSettings,
  };
}
