import { Content } from "@jobber/components/Content";
import { Page } from "@jobber/components/Page";
import React, { createContext } from "react";
import { useIntl } from "react-intl";
import { DedicatedPhoneNumberSettingWrapper } from "jobber/settings/dedicatedPhoneNumber/DedicatedPhoneNumberSettingWrapper";
import { UkKycComplianceRegistration } from "jobber/settings/dedicatedPhoneNumber/UkKycComplianceRegistration";
import type {
  TwilioRegistrationStatus,
  UkKycRegistrationRequirementTypes,
} from "~/utilities/API/graphql";
import {
  AccountContext,
  type AccountContextType,
} from "~/utilities/contexts/internal/AccountContext";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  UrlsContext,
  type UrlsContextType,
} from "~/utilities/contexts/internal/UrlsContext";
import { IntlProvider } from "@translations/IntlProvider";
import { messages } from "./messages";

interface TextMessagingSettingsPageProps {
  selectedNumber: string;
  isSelectedNumberActive: boolean;
  registrationStatus: TwilioRegistrationStatus;
  companySettingsPath: string;
  requiresRegistration: boolean;
  kycRegistrationRequirement: UkKycRegistrationRequirementTypes;
  aiReceptionistDiscoverable: boolean;
  urlsContext: UrlsContextType;
  accountContext: AccountContextType;
}
export const LinkContext = createContext("");
function TextMessagingSettingsPageInternal({
  selectedNumber,
  isSelectedNumberActive,
  registrationStatus,
  companySettingsPath,
  requiresRegistration,
  kycRegistrationRequirement,
  aiReceptionistDiscoverable,
  urlsContext,
  accountContext,
}: TextMessagingSettingsPageProps) {
  const { formatMessage } = useIntl();
  return (
    <UrlsContext.Provider value={urlsContext}>
      <Page title={formatMessage(messages.dedicatedPhoneNumberTitle)}>
        <Content>
          <Content spacing="large">
            <AccountContext.Provider value={accountContext}>
              <APIProvider>
                <UkKycComplianceRegistration />
              </APIProvider>
            </AccountContext.Provider>
            <LinkContext.Provider value={companySettingsPath}>
              <DedicatedPhoneNumberSettingWrapper
                selectedNumber={selectedNumber}
                registrationStatus={registrationStatus}
                isSelectedNumberActive={isSelectedNumberActive}
                requiresRegistration={requiresRegistration}
                kycRegistrationRequirement={kycRegistrationRequirement}
                aiReceptionistDiscoverable={aiReceptionistDiscoverable}
              />
            </LinkContext.Provider>
          </Content>
        </Content>
      </Page>
    </UrlsContext.Provider>
  );
}

export function TextMessagingSettingsPage(
  props: TextMessagingSettingsPageProps,
) {
  return (
    <IntlProvider>
      <TextMessagingSettingsPageInternal {...props} />
    </IntlProvider>
  );
}
