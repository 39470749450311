import React from "react";
import { Grid } from "@jobber/components/Grid";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { Box } from "@jobber/components/Box";
import { Button } from "@jobber/components/Button";
import { messages } from "./messages";
import image from "./Visual.png";

interface NewScheduleInfoContentProps {
  onContinue: () => void;
  onCancel: () => void;
  app: { id: string; name: string };
}

export function NewScheduleInfoContent({
  onContinue,
  onCancel,
  app,
}: NewScheduleInfoContentProps) {
  const { formatMessage } = useIntl();

  return (
    <Grid alignItems="center" gap>
      <Grid.Cell size={{ xs: 6 }}>
        <Box gap="large" margin={{ right: "largest" }}>
          <Box gap="base">
            <Heading level={2} element="span">
              {formatMessage(messages.newScheduleInfoHeading)}
            </Heading>
            <Text>
              {formatMessage(messages.newScheduleInfoText1, {
                appName: app.name,
              })}
            </Text>
            <Text>{formatMessage(messages.newScheduleInfoText2)}</Text>
          </Box>
          <Box alignItems="flex-start" gap="small" direction="row">
            <Button label="Got it, Continue" onClick={onContinue} />
            <Button label="Cancel" variation="subtle" onClick={onCancel} />
          </Box>
        </Box>
      </Grid.Cell>
      <Grid.Cell size={{ xs: 6 }}>
        <img
          src={image}
          alt={formatMessage(messages.newScheduleInfoImageAlt)}
          style={{ minHeight: "395px" }}
        />
      </Grid.Cell>
    </Grid>
  );
}
