import type { MutationFunction } from "@apollo/client";
import { useMutation } from "@apollo/client";
import type {
  ClientAutomatedReviewDetailsEditMutation,
  ClientAutomatedReviewDetailsEditMutationVariables,
} from "~/utilities/API/graphql";
import { CLIENT_AUTOMATED_REVIEWS_DETAILS_EDIT } from "../reviews.graphql";

export type EditClientAutomatedReviewDetailsReturnType = MutationFunction<
  ClientAutomatedReviewDetailsEditMutation,
  ClientAutomatedReviewDetailsEditMutationVariables
>;

export function useClientAutomatedReviewsDetailsEdit(): {
  editClientAutomatedReviewDetails: EditClientAutomatedReviewDetailsReturnType;
} {
  const [editClientAutomatedReviewDetails] = useMutation<
    ClientAutomatedReviewDetailsEditMutation,
    ClientAutomatedReviewDetailsEditMutationVariables
  >(CLIENT_AUTOMATED_REVIEWS_DETAILS_EDIT);

  return { editClientAutomatedReviewDetails };
}
