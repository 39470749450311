/* eslint-disable max-statements */
import React, {
  type Dispatch,
  type SetStateAction,
  createContext,
  useState,
} from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { shuffle } from "lodash";
import { SETUP_WIZARD_MUTATION } from "jobber/setupWizard/SetupWizard.graphql";
import type {
  CtaLookupQuery,
  SetupWizardMutation,
  SetupWizardMutationVariables,
} from "~/utilities/API/graphql";
import { Industry } from "~/utilities/API/graphql";
import { useDatadogLogger } from "~/utilities/errors/Datadog/DatadogErrorBoundaryProvider";
import { CTA_QUERY } from "~/jobber/settings/users/components/CallToAction/CallToAction.graphql";
import { SplitNames, useSplit } from "utilities/split";
import type {
  CompanyReview,
  MessageFormat,
  MutationFunction,
  SetupWizardCTAs,
  SetupWizardData,
  SetupWizardExperiments,
} from "../types";
import { topPriorityArray } from "../components/constants";
import { safelyGetEmailParts } from "../utils";

export interface SetupWizardContextType {
  error: string;
  saving: boolean;
  wizardData: SetupWizardData;
  updateWizardData: (
    newData: SetupWizardData,
    markSetupWizardComplete: boolean,
    onSuccess?: () => void,
    localSave?: boolean,
  ) => void;
  topPriorityOptions: { value: string; label: MessageFormat }[];
  experiments: SetupWizardExperiments;
  updateExperiments: Dispatch<SetStateAction<SetupWizardExperiments>>;
  accountCreatedAt: string;
  isCompanyNameManual: boolean;
  setIsCompanyNameManual: Dispatch<SetStateAction<boolean>>;
  companyPhotos: string[];
  setCompanyPhotos: Dispatch<SetStateAction<string[]>>;
  companyReviews: CompanyReview[];
  setCompanyReviews: Dispatch<SetStateAction<CompanyReview[]>>;
  ctas: SetupWizardCTAs;
  refetchCta: (ctaId: string) => Promise<boolean>;
}
interface SetupWizardProviderProps {
  children?: React.ReactNode;
  defaultState?: SetupWizardData;
  experiments?: SetupWizardExperiments;
  accountCreatedAt?: string;
  ctas?: SetupWizardCTAs;
}

export const createSetupWizardContext = (): {
  Context: React.Context<SetupWizardContextType>;
  Provider: React.FC<SetupWizardProviderProps>;
} => {
  const Context = createContext<SetupWizardContextType>(
    {} as SetupWizardContextType,
  );

  const Provider = ({
    children,
    defaultState,
    experiments: intialExperimentData,
    accountCreatedAt,
    ctas: initialCtas = {} as SetupWizardCTAs,
  }: SetupWizardProviderProps) => {
    const [wizardData, setWizardData] = useState<SetupWizardData>(
      defaultState ?? ({} as SetupWizardData),
    );
    const [experiments, setExperiments] = useState<SetupWizardExperiments>(
      intialExperimentData ?? ({} as SetupWizardExperiments),
    );
    const [error, updateError] = useState<string>("");
    const [topPriorityOptions] = useState(() => shuffle(topPriorityArray));
    const [isCompanyNameManual, setIsCompanyNameManual] = useState(false);
    const [companyPhotos, setCompanyPhotos] = useState<string[]>([]);
    const [companyReviews, setCompanyReviews] = useState<CompanyReview[]>([]);
    const { logError } = useDatadogLogger();

    const [ctas, setCtas] = useState(initialCtas);

    const accountOwnerEmailParts = safelyGetEmailParts(wizardData.email || "");
    const splitAttributes: SplitIO.Attributes =
      accountOwnerEmailParts.suffix === "getjobber.com"
        ? {
            emailUsername: accountOwnerEmailParts.prefix,
            accountCreatedAt:
              !accountCreatedAt || accountCreatedAt === ""
                ? ""
                : new Date(accountCreatedAt).getTime(),
          }
        : {
            accountCreatedAt:
              !accountCreatedAt || accountCreatedAt === ""
                ? ""
                : new Date(accountCreatedAt).getTime(),
          };

    const { getTreatmentValue } = useSplit({
      names: [SplitNames.ProgressiveOnboarding],
      attributes: splitAttributes,
    });

    const progressiveOnboardingEnabled = getTreatmentValue(
      SplitNames.ProgressiveOnboarding,
    );
    const [fetchCta] = useLazyQuery<CtaLookupQuery>(CTA_QUERY);

    const transformCtaFlagName = (ctaId: string) =>
      ctaId.replace(/_([a-z])/g, g => g[1].toUpperCase());

    const refetchCta = async (ctaId: string) => {
      if (ctaId === "progressive_onboarding") {
        if (!progressiveOnboardingEnabled) {
          return false;
        }

        if (
          wizardData.industry &&
          ![
            Industry.ELECTRICAL_CONTRACTOR,
            Industry.HVAC,
            Industry.PLUMBING,
          ].includes(wizardData.industry)
        ) {
          return false;
        }
      }

      const { data } = await fetchCta({ variables: { id: ctaId } });

      if (data?.callToAction) {
        setCtas(prevCtas => {
          const newCtas = {
            ...prevCtas,
            [transformCtaFlagName(ctaId)]: data.callToAction?.shouldShow,
          };

          return newCtas;
        });
      }

      return !!data?.callToAction?.shouldShow;
    };

    const updateQuestionsAndAnswers = (newData: SetupWizardData): void => {
      if (wizardData.questionsAndAnswers && newData.questionsAndAnswers) {
        const answeredQuestions = newData.questionsAndAnswers.map(
          x => x.question,
        );
        newData.questionsAndAnswers = [
          ...wizardData.questionsAndAnswers.filter(
            x => !answeredQuestions.includes(x.question),
          ),
          ...newData.questionsAndAnswers,
        ];
      }
    };

    const updateState = (newData: SetupWizardData) => {
      updateQuestionsAndAnswers(newData);
      const newStateData = {
        ...wizardData,
        ...newData,
      };
      setWizardData(newStateData);
    };

    const [mutateWizardData, { loading: savingSetupWizard }] = useMutation<
      SetupWizardMutation,
      SetupWizardMutationVariables
    >(SETUP_WIZARD_MUTATION);

    const handleMutation = async <TData, TVariables>(
      mutationFunc: MutationFunction<TData, TVariables>,
      variables: TVariables,
      newData: SetupWizardData,
      errorMessage: string,
      onSuccess?: () => void,
    ): Promise<void> => {
      try {
        updateError("");
        await mutationFunc({ variables });
        updateState(newData);
        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        logError(errorMessage, {}, e);
        updateError(e.toString());
      }
    };

    const updateWizardData = async (
      newData: SetupWizardData,
      markSetupWizardComplete: boolean,
      onSuccess?: () => void,
      localSave?: boolean,
    ): Promise<void> => {
      if (localSave) {
        updateState(newData);
      } else {
        await handleMutation<SetupWizardMutation, SetupWizardMutationVariables>(
          mutateWizardData,
          {
            setupWizardInput: {
              ...newData,
              completed: markSetupWizardComplete,
            },
          },
          newData,
          "SetupWizardData Mutation Error",
          onSuccess,
        );
      }
    };

    const contextValue: SetupWizardContextType = {
      error,
      saving: savingSetupWizard,
      wizardData,
      updateWizardData,
      topPriorityOptions,
      experiments,
      updateExperiments: setExperiments,
      accountCreatedAt: accountCreatedAt || "",
      isCompanyNameManual,
      setIsCompanyNameManual,
      companyPhotos,
      setCompanyPhotos,
      companyReviews,
      setCompanyReviews,
      ctas,
      refetchCta,
    };

    return (
      <Context.Provider value={contextValue}>
        <>{children}</>
      </Context.Provider>
    );
  };

  return { Context, Provider };
};
