import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Breakpoints,
  useResizeObserver,
} from "@jobber/hooks/useResizeObserver";
import {
  DragDropContext,
  Draggable,
  type DraggableProvided,
  type DropResult,
  Droppable,
  type DroppableProvided,
} from "react-beautiful-dnd";
import { showToast } from "@jobber/components/Toast";
import { useApolloClient } from "@apollo/client";
import { Icon } from "@jobber/components/Icon";
import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import { Spinner } from "@jobber/components/Spinner";
import { useSelfServeBookingsDisabledSubscription } from "components/UseSelfServeBookingsDisabledSubscription";
import type { Offering } from "jobber/settings/selfServeBookings/types";
import { useServiceSortOrderMutation } from "jobber/settings/selfServeBookings/views/OfferingsSettingsPage/hooks/useServiceSortOrderMutation";
import { useServiceDisableMutation } from "jobber/settings/selfServeBookings/views/OfferingsSettingsPage/hooks/useServiceDisableMutation";
import type { OnlineBookingServicesQuery } from "~/utilities/API/graphql";
import { SELF_SERVE_BOOKINGS_SERVICES_QUERY } from "jobber/settings/selfServeBookings/views/OfferingsSettingsPage/hooks/graphql";
import { PRODUCT_OR_SERVICE_LIST } from "jobber/settings/selfServeBookings/views/OfferingsSettingsPage/components/OfferingsCard/components/ProductAndServiceSearchModal/hooks/graphql";
import { minutesToDuration } from "utilities/time/minutesToDuration";
import { OfferingsItemLarge } from "jobber/settings/selfServeBookings/views/OfferingsSettingsPage/components/OfferingsCard/components/OfferingsList/components/OfferingsItemLarge";
import { OfferingsItemSmall } from "jobber/settings/selfServeBookings/views/OfferingsSettingsPage/components/OfferingsCard/components/OfferingsList/components/OfferingsItemSmall";
import type { SaveResult } from "jobber/workItems/components/WorkItemModal/hooks/useProductOrServiceSave";
import { WorkItemModal } from "jobber/workItems/components/WorkItemModal";
import { useAccountConfigurationContext } from "jobber/settings/selfServeBookings/components/AccountConfigurationContext";
import type { WorkItem } from "jobber/workItems/types";
import { ServicesFetchContext } from "jobber/settings/selfServeBookings/views/OfferingsSettingsPage/OfferingsSettingsPage.loader";
import { DEFAULT_SERVICES_PAGE_SIZE } from "jobber/settings/selfServeBookings/views/OfferingsSettingsPage/hooks/useQueryServices";
import styles from "./styles.module.css";
import { messages } from "./messages";

interface OfferingsListProps {
  offerings: Offering[];
  acceptingOnlineBookings: boolean;
  onError(errorMessage: Error): void;
  onSuccess(): void;
}

// eslint-disable-next-line max-statements
export function OfferingsList({
  offerings,
  acceptingOnlineBookings,
  onError,
  onSuccess,
}: OfferingsListProps) {
  const [ref, { width = Breakpoints.small }] =
    useResizeObserver<HTMLDivElement>();
  const client = useApolloClient();
  const accountContext = useAccountConfigurationContext();
  const { updateServicesSortOrder } = useServiceSortOrderMutation();
  const { disableService } = useServiceDisableMutation();

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedOffering, setSelectedOffering] = useState<Offering>();
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const servicesContext = useContext(ServicesFetchContext);

  useSelfServeBookingsDisabledSubscription(() => {
    showToast({
      message: messages.onlineBookingDisabled.message,
      variation: "success",
    });
  });

  const workItem = useMemo<WorkItem | undefined>(
    () =>
      selectedOffering
        ? {
            ...selectedOffering,
            onlineBookingsEnabled: true,
          }
        : undefined,
    [selectedOffering],
  );

  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      async targets => {
        if (targets[0].isIntersecting && servicesContext?.hasMoreServices) {
          if (servicesContext?.fetchMoreServices) {
            await servicesContext.fetchMoreServices();
          }
        }
      },
      { threshold: 0.8 },
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [servicesContext]);

  return (
    <div className={styles.listContainer}>
      <div ref={ref}>
        <DragDropContext onDragEnd={updateOfferings()}>
          <Droppable droppableId="bookable">
            {(provided: DroppableProvided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {offerings.map((offering: Offering, index: number) => {
                  const editOfferingHandler = () => handleEditClick(offering);
                  return (
                    <DraggableItemWrapper
                      key={index}
                      index={index}
                      draggableId={offering.id}
                    >
                      <SizeableOfferingItem
                        offering={offering}
                        width={width}
                        currencySymbol={accountContext.settings.currencySymbol}
                        handleRemove={handleRemoveService(offering.id)}
                        handleEditClick={editOfferingHandler}
                      />
                    </DraggableItemWrapper>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {servicesContext?.hasMoreServices && (
        <div className={styles.spinner} ref={loadMoreRef}>
          <Spinner size={"small"} />
        </div>
      )}
      {workItem && (
        <WorkItemModal
          workItem={workItem}
          currencySymbol={accountContext.settings.currencySymbol}
          advancedQuotingEnabled={
            accountContext.features.advancedQuotingEnabled
          }
          quoteMarginsEnabled={accountContext.features.quoteMarginsEnabled}
          selfServeBookingsEnabled={
            accountContext.features.selfServeBookingsEnabled
          }
          quoteTemplatesEnabled={accountContext.features.quoteTemplatesEnabled}
          onSelfServeBookingsPage={true}
          closeModal={closeEditModal}
          modalOpen={editModalOpen}
          modalTitle={`${messages.edit.message} ${workItem.name}`}
          deleteButton={{
            label: messages.remove.message,
            confirmationMessage: shouldConfirmBeforeDelete(
              offerings,
              acceptingOnlineBookings,
            )
              ? messages.removeLastServicePrompt.message
              : undefined,
            deleteOverride: handleRemoveServiceWithError,
          }}
          saveButton={{
            label: messages.save.message,
          }}
          refetchQueries={[
            SELF_SERVE_BOOKINGS_SERVICES_QUERY,
            PRODUCT_OR_SERVICE_LIST,
          ]}
        />
      )}
      {selectedOffering && (
        <ConfirmationModal
          open={deleteConfirmationModalOpen}
          confirmLabel={messages.remove.message}
          title={`${messages.remove.message} ${selectedOffering.name}?`}
          message={messages.removeLastServicePrompt.message}
          onConfirm={removeService(selectedOffering.id)}
          onRequestClose={closeConfirmationModal}
          size="small"
          variation="destructive"
        />
      )}
    </div>
  );

  function updateOfferings() {
    return function (
      result: Pick<DropResult, "source" | "destination" | "draggableId">,
    ) {
      const { source, destination } = result;
      const originalOfferings = [...offerings];
      const sourceServiceId = offerings[source.index].id;
      const reorderedOfferings = reorderList(originalOfferings, result);
      if (!reorderedOfferings) {
        return;
      }
      const directiveVariables = {
        advancedQuotingEnabled: accountContext.features.advancedQuotingEnabled,
        quoteMarginsEnabled: accountContext.features.quoteMarginsEnabled,
        first: DEFAULT_SERVICES_PAGE_SIZE,
        after: null as string | null,
      };
      const cachedQuery = client.readQuery<OnlineBookingServicesQuery>({
        query: SELF_SERVE_BOOKINGS_SERVICES_QUERY,
        variables: directiveVariables,
      });

      const onlineBookingServices = {
        nodes: reorderedOfferings.map(item => ({
          ...item,
          __typename: "OnlineBookingService",
        })),
        pageInfo: cachedQuery?.onlineBookingServices.pageInfo,
        totalCount: reorderedOfferings.length,
      };

      client.writeQuery({
        query: SELF_SERVE_BOOKINGS_SERVICES_QUERY,
        data: { ...cachedQuery, onlineBookingServices },
        variables: directiveVariables,
      });
      updateServicesSortOrder({
        variables: {
          input: {
            sourceId: sourceServiceId,
            targetPos: destination?.index,
          },
          ...directiveVariables,
        },
      })
        .then(() => {
          onSuccess();
          showToast({
            message: messages.orderSaved.message,
            variation: "success",
          });
        })
        .catch(() => {
          client.writeQuery({
            query: SELF_SERVE_BOOKINGS_SERVICES_QUERY,
            data: cachedQuery,
            variables: directiveVariables,
          });
          onError(new Error(messages.genericError.message));
        });
    };
  }

  async function handleRemoveServiceWithError(id: string): Promise<SaveResult> {
    try {
      const { data } = await disableService({
        variables: {
          id: id,
        },
      });

      if (data?.data.userErrors) {
        return { errors: data.data.userErrors.map(error => error.message) };
      }
    } catch (error) {
      return { errors: [error.message] };
    }
    return { errors: [] };
  }

  function handleRemoveService(serviceId: string) {
    if (shouldConfirmBeforeDelete(offerings, acceptingOnlineBookings)) {
      return function () {
        setSelectedOffering(offerings.find(x => x.id === serviceId));
        setDeleteConfirmationModalOpen(true);
      };
    }

    return removeService(serviceId);
  }

  function removeService(serviceId: string) {
    return async function () {
      const removeErrors = await handleRemoveServiceWithError(serviceId);
      if (!removeErrors.errors.length) {
        onSuccess();
      } else {
        onError(new Error(messages.genericError.message));
      }
    };
  }

  function closeEditModal() {
    setEditModalOpen(false);
  }

  function handleEditClick(offering: Offering) {
    setSelectedOffering(offering);
    setEditModalOpen(true);
  }

  function closeConfirmationModal() {
    setDeleteConfirmationModalOpen(false);
  }
}

function shouldConfirmBeforeDelete(
  offerings: Offering[],
  acceptingOnlineBookings: boolean,
) {
  return offerings.length == 1 && acceptingOnlineBookings;
}

interface SizeableOfferingItemProps {
  offering: Offering;
  width: number;
  currencySymbol: string;
  handleRemove(): void;
  handleEditClick(): void;
}
export function SizeableOfferingItem({
  offering,
  width,
  currencySymbol,
  handleRemove,
  handleEditClick,
}: SizeableOfferingItemProps): React.ReactElement {
  const componentIsLarge = width > Breakpoints.base;
  const props = {
    offering: offering,
    currencySymbol: currencySymbol,
    duration: minutesToDuration(offering.durationMinutes),
    handleRemove: handleRemove,
    onClick: handleEditClick,
  };

  return componentIsLarge ? (
    <OfferingsItemLarge {...props} />
  ) : (
    <OfferingsItemSmall {...props} />
  );
}

interface DraggableItemProps {
  index: number;
  draggableId: string;
  children: React.ReactElement;
}
export function DraggableItemWrapper({
  index,
  draggableId,
  children,
}: DraggableItemProps) {
  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index}>
      {(providedDraggable: DraggableProvided) => (
        <div
          className={styles.itemContainer}
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
        >
          <div
            className={styles.draggableItemIconContainer}
            {...providedDraggable.dragHandleProps}
          >
            <Icon name="drag" size="small" />
          </div>
          {children}
        </div>
      )}
    </Draggable>
  );
}

export function reorderList(
  originalOrderOfferings: Offering[],
  result: Pick<DropResult, "source" | "destination" | "draggableId">,
): Offering[] | undefined {
  const { source, destination, draggableId } = result;
  const newOfferings = [...originalOrderOfferings];
  const draggedOffering = newOfferings.find(
    element => element.id === draggableId,
  );

  if (
    !draggedOffering ||
    !destination ||
    (destination.droppableId === source.droppableId &&
      destination.index === source.index)
  ) {
    return;
  }

  newOfferings.splice(source.index, 1);
  newOfferings.splice(destination.index, 0, draggedOffering);

  return newOfferings;
}
