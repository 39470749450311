import React, { useState } from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Markdown } from "@jobber/components/Markdown";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { useIntl } from "react-intl";
import { Tooltip } from "@jobber/components/Tooltip";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from "jobber/marketplace/ANALYTICS_EVENTS";
import styles from "./ActionButtons.module.css";
import { AppDisconnectModal } from "./AppDisconnectModal";
import { GpsTrackingSignupOrConnectModal } from "./GpsTrackingSignupOrConnectModal";
import { ChangePlanModal } from "../ChangePlanModal";
import { messages } from "../../messages";
import type { GPSTrackingMetadata } from "../../IntegrationProps";

export interface ActionButtonsProps {
  id: string;
  title: string;
  isConnected: boolean;
  oauthUrl?: string;
  manageAppUrl?: string;
  requiredPlan?: string;
  isCustomFieldInfoModalOpen: boolean;
  setCustomFieldInfoModalOpen(val: boolean): void;
  handleDisconnect(): void;
  publicFacing: boolean;
  isAdmin: boolean;
  addOnBlockingConnection: boolean;
  externalPurchaseUrl?: string;
  externalPurchaseUrlText?: string;
  externalPurchaseLearnMoreText?: string;
  gpsTrackingMetadata?: GPSTrackingMetadata;
}

// eslint-disable-next-line max-statements
export function ActionButtons({
  id,
  title,
  isConnected,
  requiredPlan,
  oauthUrl,
  manageAppUrl,
  handleDisconnect,
  isCustomFieldInfoModalOpen,
  setCustomFieldInfoModalOpen,
  publicFacing,
  isAdmin,
  addOnBlockingConnection,
  externalPurchaseUrl,
  externalPurchaseUrlText,
  externalPurchaseLearnMoreText,
  gpsTrackingMetadata,
}: ActionButtonsProps) {
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);
  const [isChangePlanModalOpen, setIsChangePlanModalOpen] = useState(false);
  const [
    isGpsTrackingSignupOrConnectModalOpen,
    setIsGpsTrackingSignupOrConnectModalOpen,
  ] = useState(false);

  function trackEvent(event: string) {
    Amplitude.TRACK_EVENT(event, {
      appId: id,
      appName: title,
      source: ANALYTICS_PROPERTIES.marketplacePageSource,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      public_marketplace: publicFacing,
    });
  }

  async function handleConfirmDisconnect() {
    trackEvent(ANALYTICS_EVENTS.clickDisconnectConfirm);
    await handleDisconnect();
  }

  function handleCancelDisconnect() {
    trackEvent(ANALYTICS_EVENTS.clickDisconnectCancel);
    toggleDisconnectModal();
  }

  function handleManageCustomFields() {
    window.open("/custom_fields", "_self")?.focus();
  }

  function handleCancelManageCustomFields() {
    toggleManageCustomFieldsModal();
  }

  function handleCancelChangePlan() {
    toggleChangePlanModal();
  }

  function handleConnect() {
    if (oauthUrl) {
      trackEvent(ANALYTICS_EVENTS.clickConnect);
      window.open(oauthUrl, "_blank")?.focus();
    }
  }

  function handleGpsTrackingRedirection() {
    window.open(gpsTrackingMetadata?.purchasingUrl, "_blank")?.focus();
  }

  function toggleDisconnectModal(): void {
    setIsDisconnectModalOpen(!isDisconnectModalOpen);
  }

  function toggleManageCustomFieldsModal(): void {
    setCustomFieldInfoModalOpen(!isCustomFieldInfoModalOpen);
  }

  function toggleChangePlanModal(): void {
    setIsChangePlanModalOpen(!isChangePlanModalOpen);
  }

  function toggleGpsTrackingSignupOrConnectModal(): void {
    setIsGpsTrackingSignupOrConnectModalOpen(
      !isGpsTrackingSignupOrConnectModalOpen,
    );
  }

  return (
    <div className={styles.actionButtons}>
      {publicFacing && <PublicFacingButtons handleConnect={handleConnect} />}
      {!publicFacing && isAdmin && (
        <LoggedInAdminButtons
          {...{
            isConnected,
            trackEvent,
            toggleDisconnectModal,
            toggleChangePlanModal,
            toggleGpsTrackingSignupOrConnectModal,
            requiredPlan,
            handleConnect,
            manageAppUrl,
            addOnBlockingConnection,
            externalPurchaseUrl,
            externalPurchaseUrlText,
            externalPurchaseLearnMoreText,
            gpsTrackingMetadata,
          }}
        />
      )}
      {!publicFacing && !isAdmin && (
        <LoggedInNonAdminButtons
          {...{
            isConnected,
            trackEvent,
            toggleDisconnectModal,
            requiredPlan,
            handleConnect,
            manageAppUrl,
          }}
        />
      )}
      <AppDisconnectModal
        appName={title}
        openDisconnectedModal={isDisconnectModalOpen}
        onConfirmDisconnect={handleConfirmDisconnect}
        onCancelDisconnect={handleCancelDisconnect}
        openManageCustomFieldsModal={isCustomFieldInfoModalOpen}
        onManageCustomFields={handleManageCustomFields}
        onCancelManageCustomFields={handleCancelManageCustomFields}
      />
      <ChangePlanModal
        appId={id}
        appName={title}
        requiredPlan={requiredPlan as string}
        openChangePlanModal={isChangePlanModalOpen}
        onCancelChangePlan={handleCancelChangePlan}
      />
      <GpsTrackingSignupOrConnectModal
        open={isGpsTrackingSignupOrConnectModalOpen}
        onRequestClose={toggleGpsTrackingSignupOrConnectModal}
        onConnect={handleConnect}
        onGetStarted={handleGpsTrackingRedirection}
        app={{ id, name: title }}
        hasNewScheduleEnabled={gpsTrackingMetadata?.hasNewScheduleEnabled}
      />
    </div>
  );
}

interface LoggedInAdminProps {
  isConnected: boolean;
  requiredPlan?: string;
  manageAppUrl?: string;
  handleConnect(): void;
  trackEvent(event: string): void;
  toggleDisconnectModal(): void;
  toggleChangePlanModal(): void;
  toggleGpsTrackingSignupOrConnectModal(): void;
  addOnBlockingConnection: boolean;
  externalPurchaseUrl?: string;
  externalPurchaseUrlText?: string;
  externalPurchaseLearnMoreText?: string;
  gpsTrackingMetadata?: GPSTrackingMetadata;
}

function LoggedInAdminButtons({
  isConnected,
  requiredPlan,
  manageAppUrl,
  handleConnect,
  trackEvent,
  toggleDisconnectModal,
  toggleChangePlanModal,
  toggleGpsTrackingSignupOrConnectModal,
  addOnBlockingConnection,
  externalPurchaseUrl,
  externalPurchaseUrlText,
  externalPurchaseLearnMoreText,
  gpsTrackingMetadata,
}: LoggedInAdminProps) {
  function handleManageAppButton() {
    if (manageAppUrl) {
      trackEvent(ANALYTICS_EVENTS.clickManageApp);
      window.open(manageAppUrl, "_blank")?.focus();
    }
  }

  function handleDisconnectButton() {
    trackEvent(ANALYTICS_EVENTS.clickDisconnect);
    toggleDisconnectModal();
  }

  function handleConnectChangePlanButton() {
    toggleChangePlanModal();
  }

  function handleConnectGpsTrackingButton() {
    toggleGpsTrackingSignupOrConnectModal();
  }

  return (
    <>
      {requiredPlan ? (
        <Content spacing="small">
          <Button
            fullWidth
            label="Connect"
            type="primary"
            size="large"
            onClick={handleConnectChangePlanButton}
          />
        </Content>
      ) : (
        <>
          {isConnected && manageAppUrl && (
            <Button
              label="Manage App"
              size="large"
              onClick={handleManageAppButton}
            />
          )}
          {isConnected && (
            <Button
              label="Disconnect"
              type="secondary"
              variation="destructive"
              size="large"
              onClick={handleDisconnectButton}
            />
          )}
          {!isConnected && !addOnBlockingConnection && (
            <Button
              label="Connect"
              type="primary"
              size="large"
              onClick={
                gpsTrackingMetadata
                  ? handleConnectGpsTrackingButton
                  : handleConnect
              }
            />
          )}
          {externalPurchaseUrl && externalPurchaseUrlText && (
            <Button
              label={externalPurchaseUrlText!}
              type="secondary"
              size="large"
              onClick={() => {
                window.open(externalPurchaseUrl!, "_blank")?.focus();
              }}
            />
          )}
        </>
      )}
      {externalPurchaseLearnMoreText && (
        <div>
          <Markdown
            content={externalPurchaseLearnMoreText}
            basicUsage={undefined}
            externalLink={true}
          />
        </div>
      )}
    </>
  );
}

interface LoggedInNonAdminProps {
  isConnected: boolean;
  requiredPlan?: string;
  manageAppUrl?: string;
  handleConnect(): void;
  trackEvent(event: string): void;
  toggleDisconnectModal(): void;
}

function LoggedInNonAdminButtons({ isConnected }: LoggedInNonAdminProps) {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.nonAdminActionButtons}>
      {!isConnected ? (
        <>
          <Content spacing={"small"}>
            <Text variation="subdued">
              <Emphasis variation="italic">
                {formatMessage(messages.nonAdminConnectMessage)}
              </Emphasis>
            </Text>
            <Text variation="subdued">
              <Emphasis variation="italic">
                {formatMessage(messages.nonAdminConnectContactAdministrator)}
              </Emphasis>
            </Text>
          </Content>
          <Tooltip
            message={formatMessage(messages.nonAdminConnectPopoverMessage)}
          >
            <span className={styles.nonAdminActionButton}>
              <Button
                label="Connect"
                type="primary"
                size="large"
                disabled={true}
              />
            </span>
          </Tooltip>
        </>
      ) : (
        <>
          <Content spacing={"small"}>
            <Text variation="subdued">
              <Emphasis variation="italic">
                {formatMessage(messages.nonAdminManageAppMessage)}
              </Emphasis>
            </Text>
          </Content>
          <Tooltip message={formatMessage(messages.nonAdminManageAppMessage)}>
            <span className={styles.nonAdminActionButton}>
              <Button label="Manage App" size="large" disabled={true} />
            </span>
          </Tooltip>
        </>
      )}
    </div>
  );
}

interface PublicFacingButtonsProps {
  handleConnect(): void;
}

function PublicFacingButtons({ handleConnect }: PublicFacingButtonsProps) {
  return (
    <Content spacing="small">
      <Button
        label="Connect"
        type="primary"
        size="large"
        onClick={handleConnect}
      />
    </Content>
  );
}
