import { gql } from "@apollo/client";

export const ACCOUNT_ADDON_INFO_REVIEWS_PURCHASE_PAGE = gql`
  query AccountAddonInfoReviewsPurchasePage($addonSetIdentifier: String!) {
    accountAddonInfo(addonSetIdentifier: $addonSetIdentifier) {
      identifier
      baseIdentifier
      name
      includedWithAddonSetIdentifiers
      monthlyBillingCycle {
        monthlyCost
        name
      }
    }
    addonDiscountGroup(addonSetIdentifier: $addonSetIdentifier) {
      monthlyAddonDiscount {
        discountCallToAction
        addonCostMonthlyDiscounted
      }
    }
  }
`;
