import { useMutation } from "@apollo/client";
import type {
  ReviewAttributionsBulkEditMutation,
  ReviewAttributionsBulkEditMutationVariables,
} from "~/utilities/API/graphql";
import { REVIEW_ATTRIBUTION_BULK_EDIT } from "../../../graphql";

export function useReviewAttributionsBulkEditMutation() {
  const [bulkEdit, { data, error }] = useMutation<
    ReviewAttributionsBulkEditMutation,
    ReviewAttributionsBulkEditMutationVariables
  >(REVIEW_ATTRIBUTION_BULK_EDIT);

  const hasError =
    Boolean(error) || Boolean(data?.reviewAttributionsEdit?.userErrors?.length);

  return { bulkEdit, hasError };
}
