import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Spinner } from "@jobber/components/Spinner";
import { showToast } from "@jobber/components/Toast";
import {
  ActionButtons,
  type ActionButtonsProps,
} from "jobber/marketplace/components/ActionButtons/ActionButtons";
import type {
  AppAddonsQuery,
  AppAddonsQueryVariables,
  AppRemoveMutation,
  AppRemoveMutationVariables,
  CustomFieldPageListQuery,
  CustomFieldPageListQueryVariables,
} from "~/utilities/API/graphql";
import { Rollbar } from "~/utilities/errors/Rollbar";
import { CUSTOM_FIELD_LIST } from "jobber/customFields/CustomFieldsPage/hooks/CustomFieldData.graphql";
import { useCurrentUser } from "jobber/settings/users/hooks/useCurrentUser";

export const APPLICATION_REMOVE_MUTATION = gql`
  mutation AppRemove($appId: EncodedId!) {
    appRemove(appId: $appId) {
      userErrors {
        message
      }
    }
  }
`;

export const APP_ADDON_QUERY = gql`
  query AppAddons($appId: EncodedId!) {
    appAddOns(appId: $appId) {
      addOnBlockingConnection
      externalPurchaseLearnMoreText
      externalPurchaseUrl
      externalPurchaseUrlText
    }
  }
`;

export interface ActionButtonsLoaderProps
  extends Omit<
    ActionButtonsProps,
    | "handleDisconnect"
    | "hasCustomFields"
    | "isCustomFieldInfoModalOpen"
    | "setCustomFieldInfoModalOpen"
    | "isAdmin"
    | "addOnBlockingConnection"
  > {
  id: string;
  setIsAppConnected: React.Dispatch<React.SetStateAction<boolean>>;
}

// eslint-disable-next-line max-statements
export function ActionButtonsLoader({
  id,
  title,
  isConnected,
  requiredPlan,
  oauthUrl,
  manageAppUrl,
  publicFacing,
  setIsAppConnected,
  gpsTrackingMetadata,
}: ActionButtonsLoaderProps) {
  const [isCustomFieldInfoModalOpen, setCustomFieldInfoModalOpen] =
    useState(false);

  const { loading: currentUserLoading, currentUser } = useCurrentUser([
    "isAdmin",
  ]);

  const [applicationDisconnect, { loading: mutationLoading }] = useMutation<
    AppRemoveMutation,
    AppRemoveMutationVariables
  >(APPLICATION_REMOVE_MUTATION);

  const { loading: queryLoading, data: queryData } = useQuery<
    CustomFieldPageListQuery,
    CustomFieldPageListQueryVariables
  >(CUSTOM_FIELD_LIST);

  const { loading: addOnQueryLoading, data: addOnQueryData } = useQuery<
    AppAddonsQuery,
    AppAddonsQueryVariables
  >(APP_ADDON_QUERY, { variables: { appId: id } });

  const customFieldsCreatedByApps =
    queryData?.customFieldConfigurations.nodes.filter(
      field => field.app?.id === id,
    );
  const hasCustomFields =
    customFieldsCreatedByApps && customFieldsCreatedByApps.length > 0;

  const addOnBlockingConnection =
    !!addOnQueryData?.appAddOns?.addOnBlockingConnection;

  const externalPurchaseUrl = addOnQueryData?.appAddOns?.externalPurchaseUrl;

  const externalPurchaseUrlText =
    addOnQueryData?.appAddOns?.externalPurchaseUrlText;

  const externalPurchaseLearnMoreText =
    addOnQueryData?.appAddOns?.externalPurchaseLearnMoreText;

  useEffect(() => {
    window.addEventListener("storage", handleStorage);

    return () => {
      removeEventListener("storage", handleStorage);
    };
  }, []);

  if (
    addOnQueryLoading ||
    queryLoading ||
    mutationLoading ||
    currentUserLoading
  ) {
    return <Spinner />;
  }

  return (
    <ActionButtons
      id={id}
      title={title}
      isConnected={isConnected}
      requiredPlan={requiredPlan}
      oauthUrl={oauthUrl}
      manageAppUrl={manageAppUrl}
      handleDisconnect={handleDisconnect}
      isCustomFieldInfoModalOpen={isCustomFieldInfoModalOpen}
      setCustomFieldInfoModalOpen={setCustomFieldInfoModalOpen}
      publicFacing={publicFacing}
      isAdmin={!!currentUser?.isAdmin}
      addOnBlockingConnection={addOnBlockingConnection}
      externalPurchaseUrl={externalPurchaseUrl}
      externalPurchaseUrlText={externalPurchaseUrlText}
      externalPurchaseLearnMoreText={externalPurchaseLearnMoreText}
      gpsTrackingMetadata={gpsTrackingMetadata}
    />
  );

  interface AppAccessChangeEvent {
    title: string;
    isConnected: boolean;
  }

  function handleStorage(event: StorageEvent) {
    if (
      event.storageArea !== localStorage ||
      event.key !== "appAccessChangeEvent" ||
      event.newValue === null
    ) {
      return;
    }

    const { title: eventTitle, isConnected: eventIsConnected } = JSON.parse(
      event.newValue,
    ) as AppAccessChangeEvent;

    if (eventTitle === title) {
      setIsAppConnected(eventIsConnected);
    }
  }

  async function handleDisconnect() {
    try {
      const result = await applicationDisconnect({
        variables: {
          appId: id,
        },
      });

      const errors = result.data?.appRemove.userErrors;

      if (errors && errors.length > 0) {
        throw new Error(errors[0].message);
      } else {
        setIsAppConnected(false);
        if (hasCustomFields) {
          setCustomFieldInfoModalOpen(true);
        }
      }
    } catch (e) {
      showToast({
        message: "App was not disconnected",
        variation: "error",
      });
      Rollbar.EXECUTE("Error Disconnecting an App", e);
    }
  }
}
