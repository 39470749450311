import { gql } from "@apollo/client";

export const GOOGLE_BUSINESS_ACCOUNT_EDIT_MUTATION = gql`
  mutation googleBusinessAccountEdit($input: GoogleBusinessAccountInput!) {
    googleBusinessAccountEdit(input: $input) {
      googleBusinessAccount {
        id
        isConnected
        primaryLocationName
        reviewUrl
        deliveryMethod
      }
      userErrors {
        message
        path
      }
    }
  }
`;

export const CLIENT_AUTOMATED_REVIEWS_DETAILS_EDIT = gql`
  mutation ClientAutomatedReviewDetailsEdit(
    $input: ClientAutomatedReviewDetailsEditInput!
  ) {
    clientAutomatedReviewDetailsEdit(input: $input) {
      clientAutomatedReviewDetails {
        allowReviewRequests
      }
      userErrors {
        path
        message
      }
    }
  }
`;

export const REVIEWS_INITIAL_MESSAGE_SETTINGS_EDIT_MUTATION = gql`
  mutation reviewsInitialMessageSettingsEdit(
    $input: InitialMessageSettingsInput!
  ) {
    reviewsInitialMessageSettingsEdit(input: $input) {
      initialMessageSettings {
        id
        triggerType
        deliveryMethod
        triggerUnit
        triggerValue
      }
      userErrors {
        message
      }
    }
  }
`;
