const typeToUrlPathsMap = {
  Client: "clients",
  Invoice: "invoices",
  Quote: "quotes",
  WorkOrder: "work_orders",
  WorkRequest: "work_requests",
};

interface ReferencedObject {
  id: number;
  objectUrlPath: string | undefined;
  textRepresentation: string;
  type: string;
}

export function addReferencesToResponse(
  markdownResponse: string,
  referencedObjects: Omit<ReferencedObject, "objectUrlPath">[],
) {
  const referencedObjectsWithUrlPaths =
    annotateReferencedObjects(referencedObjects);

  return referencedObjectsWithUrlPaths.reduce(
    (markdownWithLinks, { id, objectUrlPath, textRepresentation }) => {
      if (objectUrlPath) {
        return markdownWithLinks.replace(
          // Only matches the first instance of the text representation
          new RegExp(textRepresentation),
          match => `[${match}](/${objectUrlPath}/${id})`,
        );
      }

      return markdownWithLinks;
    },
    markdownResponse,
  );
}

function annotateReferencedObjects(
  objects: Omit<ReferencedObject, "objectUrlPath">[],
): ReferencedObject[] {
  return objects.map(object => ({
    ...object,
    objectUrlPath: isKnownUrlPath(object.type)
      ? typeToUrlPathsMap[object.type]
      : undefined,
    // Escape special characters in the target string, as this can break the RegExp
    textRepresentation: object.textRepresentation.replace(
      /[-/\\^$*+?.()|[\]{}]/g,
      "\\$&",
    ),
  }));
}

function isKnownUrlPath(type: string): type is keyof typeof typeToUrlPathsMap {
  return type in typeToUrlPathsMap;
}
