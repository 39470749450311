import React, { useEffect, useState } from "react";
import { Content } from "@jobber/components/Content";
import { Popover } from "@jobber/components/Popover";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Button, type ButtonProps } from "@jobber/components/Button";
import classNames from "classnames";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { useCta } from "~/utilities/contexts/internal/useCtas";
import { withRailsPropsAsContexts } from "~/utilities/contexts/internal/withRailsPropsAsContexts";
import styles from "./PopoverGuide.module.css";

interface Step {
  cssSelector: string;
  title: string;
  content: string;
  preferredPlacement: "right" | "top" | "bottom" | "left" | "auto" | undefined;
  primaryCta: string;
  secondaryCta: string | undefined;
}

const STEPS: Step[] = [
  {
    cssSelector: ".js-sample-data-template-guide-step-one",
    title: "Make it yours",
    content:
      "Add the products and services that are specific to your business.",
    preferredPlacement: "top",
    primaryCta: "Next",
    secondaryCta: undefined,
  },
  {
    cssSelector: ".js-sample-data-template-guide-step-two",
    title: "Preview your quote",
    content:
      "Send this quote to yourself to experience what your customers will see.",
    preferredPlacement: "bottom",
    primaryCta: "Get Started",
    secondaryCta: "Previous",
  },
];

const DIALOG_NAME = "Sample Data Template Guide";

function PopoverGuide() {
  const [currentStep, setCurrentStep] = useState(1);
  const [showCTA, dismissCTA] = useCta("sample_data_template_cta");
  const {
    cssSelector,
    title,
    content,
    primaryCta,
    secondaryCta,
    preferredPlacement,
  } = STEPS[currentStep - 1];
  const ref = $(`${cssSelector}:visible`)[0];
  const totalSteps = STEPS.length;
  const lastStep = currentStep >= totalSteps;

  useEffect(() => {
    if (ref) {
      ref.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, [ref]);

  useEffect(() => {
    if (showCTA) {
      Amplitude.TRACK_EVENT("Viewed Dialog", {
        name: DIALOG_NAME,
      });
    }
  }, [showCTA]);

  const handleOnRequestClose = () => {
    Amplitude.TRACK_EVENT("Interacted with Dialog", {
      name: DIALOG_NAME,
      interaction: "Dismissed Guide",
      step: currentStep,
    });
    dismissCTA();
  };

  const handleOnClickPrimaryCta = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.currentTarget.blur();

    if (lastStep) {
      Amplitude.TRACK_EVENT("Interacted with Dialog", {
        name: DIALOG_NAME,
        interaction: "Clicked Get Started",
      });
      dismissCTA();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleOnClickSecondaryCta = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.currentTarget.blur();
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      <div className={classNames({ [styles.overlay]: showCTA })} />
      <div className={styles.popoverContainer}>
        <Popover
          UNSAFE_className={{ container: styles.popoverDialog }}
          attachTo={ref}
          open={showCTA}
          preferredPlacement={preferredPlacement}
          onRequestClose={handleOnRequestClose}
        >
          <Content>
            <Heading level={3}>{title}</Heading>
            <Text>{content}</Text>
            <div className={styles.bottomBar}>
              <span className={styles.stepLabel}>
                <InlineLabel>
                  {currentStep} of {totalSteps}
                </InlineLabel>
              </span>
              {secondaryCta && (
                <Button
                  label={secondaryCta}
                  type="tertiary"
                  variation="learning"
                  onClick={handleOnClickSecondaryCta as ButtonProps["onClick"]}
                />
              )}
              {primaryCta && (
                <Button
                  label={primaryCta}
                  variation="learning"
                  onClick={handleOnClickPrimaryCta as ButtonProps["onClick"]}
                />
              )}
            </div>
          </Content>
        </Popover>
      </div>
    </>
  );
}

const Enhanced = withRailsPropsAsContexts()(PopoverGuide);
export { Enhanced as PopoverGuide };
