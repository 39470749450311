import React from "react";

interface VideoPreviewProps {
  description: string;
  videoUrl: string;
  title: string;
}

export function VideoPreview({
  description,
  title,
  videoUrl,
}: VideoPreviewProps) {
  return (
    <iframe
      data-testid={`${title}-video`}
      width="340"
      height="191"
      src={videoUrl}
      aria-label={description}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    />
  );
}
