import React, {
  type MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button } from "@jobber/components/Button";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import type { IconNames } from "@jobber/design";
import type { AddonUpsellProps } from "~/jobber/billing/context/AddonUpsellContext/AddonUpsellContext";
import { PurchaseFormContextProvider } from "jobber/billing/hooks/PurchaseFormContext";
import {
  DISMISS_INTERACTION,
  PAY_NOW_INTERACTION,
  type TrackingDetails,
  trackFailedSubmitRecurlyForm,
  trackInteractedWithButton,
} from "jobber/billing/utils/trackInteractions";
import type {
  MutationErrors,
  SubscriptionAddonsAddMutation,
  SubscriptionAddonsAddMutationVariables,
} from "~/utilities/API/graphql";
import type { EditBillingInfoRef } from "jobber/billing/components/EditBillingInfo";
import type {
  FieldErrorState,
  FormErrorState,
} from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import { PurchaseFormErrors } from "jobber/billing/components/PurchaseFormErrors";
import { ACCOUNT_ADDONS_INFO } from "jobber/billing/features/SubscriptionAddons/SubscriptionAddons.graphql";
import { messages } from "./messages";
import { AddonPurchaseForm } from "./components/AddonPurchaseForm";
import { ACCOUNT_ADDONS_ADD } from "./AddonPurchaseModal.graphql";

export interface PurchaseModalButtonProps {
  buttonSize?: "small" | "base" | "large";
  buttonType?: "primary" | "secondary" | "tertiary";
  buttonText?: string;
  ariaLabel?: string;
  disabled?: boolean;
  icon?: IconNames;
  buttonFullWidth?: boolean;
  onClick?: () => boolean;
  loading?: boolean;
}

export interface ModalActionProps {
  buttonText?: string;
  onClick?: () => void;
}

interface BaseAddonModalProps {
  recurlyPublicKey: string;
  addonSetIdentifier: string;
  addonUpsell?: AddonUpsellProps;
  successAction?: () => void;
  onDismiss?: () => void;
  modalActionProps?: ModalActionProps;
}

export interface AddonPurchaseModalProps
  extends Pick<BaseAddonModalProps, keyof BaseAddonModalProps> {
  buttonProps?: PurchaseModalButtonProps;
  onOpen?: () => void;
  defaultToOpen?: boolean;
}

export interface AddonPurchaseModalContentProps
  extends Pick<BaseAddonModalProps, keyof BaseAddonModalProps> {
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
}
export const ADDON_PURCHASE = "Add-on Purchase";

export function AddonPurchaseModal(props: AddonPurchaseModalProps) {
  const {
    recurlyPublicKey,
    addonSetIdentifier,
    addonUpsell,
    successAction,
    buttonProps,
    modalActionProps,
    onDismiss,
    onOpen,
    defaultToOpen,
  } = props;

  const [modalOpen, setModalOpen] = useState(!!defaultToOpen);
  const { formatMessage } = useIntl();

  return (
    <>
      <Button
        size={buttonProps?.buttonSize || "small"}
        label={buttonProps?.buttonText || formatMessage(messages.addToPlan)}
        ariaLabel={buttonProps?.ariaLabel}
        icon={buttonProps?.icon}
        type={buttonProps?.buttonType || "primary"}
        variation={"work"}
        loading={buttonProps?.loading || false}
        disabled={buttonProps?.disabled}
        onClick={() => {
          if (buttonProps?.onClick) {
            buttonProps.onClick() && toggleModal();
          } else {
            toggleModal();
          }
        }}
        fullWidth={buttonProps?.buttonFullWidth}
        id={"addons-add-to-plan-button"}
      />
      <AddonPurchaseModalContent
        recurlyPublicKey={recurlyPublicKey}
        addonSetIdentifier={addonSetIdentifier}
        addonUpsell={addonUpsell}
        successAction={successAction}
        modalActionProps={modalActionProps}
        onDismiss={onDismiss}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </>
  );

  function toggleModal() {
    setModalOpen(!modalOpen);
    onOpen?.();
  }
}

// eslint-disable-next-line max-statements
export function AddonPurchaseModalContent(
  props: AddonPurchaseModalContentProps,
) {
  const {
    recurlyPublicKey,
    addonSetIdentifier,
    modalOpen,
    setModalOpen,
    addonUpsell,
    successAction,
    modalActionProps,
    onDismiss,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [showActions, setShowActions] = useState(true);
  const [submissionErrors, setSubmissionErrors] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<FormErrorState>({});
  const [selectedAddonCode, setSelectedAddonCode] = useState<
    string | undefined
  >();
  const [selectedAddonName, setSelectedAddonName] = useState<
    string | undefined
  >();

  const ref = useRef() as MutableRefObject<EditBillingInfoRef>;
  const { formatMessage } = useIntl();

  const [addonsAdd] = useMutation<
    SubscriptionAddonsAddMutation,
    SubscriptionAddonsAddMutationVariables
  >(ACCOUNT_ADDONS_ADD, {
    refetchQueries: [ACCOUNT_ADDONS_INFO],
  });

  const trackingDetails: TrackingDetails = {
    name: ADDON_PURCHASE,
    addonName: selectedAddonName,
  };

  useEffect(() => {
    setSubmissionErrors([]);
    setValidationErrors({});
    setIsSubmitting(false);
  }, [modalOpen]);

  const hasValidationError = Object.values(validationErrors).some(
    error => !!error,
  );

  return (
    <PurchaseFormContextProvider submitting={isSubmitting}>
      <Modal
        title={formatMessage(messages.modalTitle)}
        open={modalOpen}
        onRequestClose={handleDismiss}
        primaryAction={
          showActions
            ? {
                label:
                  modalActionProps?.buttonText ||
                  formatMessage(messages.submitButtonLabel),
                onClick: async () => {
                  if (modalActionProps?.onClick) {
                    modalActionProps.onClick();
                    await submitForm();
                  } else {
                    await submitForm();
                  }
                },
                loading: isSubmitting,
                disabled: !saveEnabled || hasValidationError,
                id: "addons-subscribe-button", // Note: This is used by Google Tag Manager. Do not change this ID without updating Google Tag Manager.
              }
            : undefined
        }
      >
        <Content>
          <PurchaseFormErrors errors={submissionErrors} />
          <AddonPurchaseForm
            ref={ref}
            recurlyPublicKey={recurlyPublicKey}
            addonSetIdentifier={addonSetIdentifier}
            addonUpsell={addonUpsell}
            trackingDetails={trackingDetails}
            setSaveEnabled={setSaveEnabled}
            setShowParentActions={setShowActions}
            setSelectedAddonCode={setSelectedAddonCode}
            setSelectedAddonName={setSelectedAddonName}
            onSubmitBillingInfoSuccess={callPurchaseMutation}
            onSubmitBillingInfoError={onSubmitError}
            onValidationError={onValidationError}
            clearValidationErrors={clearValidationErrors}
          />
        </Content>
      </Modal>
    </PurchaseFormContextProvider>
  );

  async function submitForm() {
    setSubmissionErrors([]);
    setIsSubmitting(true);
    trackInteractedWithButton({
      ...trackingDetails,
      interaction: PAY_NOW_INTERACTION,
    });

    ref.current && (await ref.current.submit());
  }

  async function callPurchaseMutation() {
    const result = await purchaseMutation();

    const userErrors: MutationErrors[] | undefined =
      result?.data?.subscriptionAddonsAdd?.userErrors;

    const isSuccessfulSubmission =
      !userErrors?.length && !!result?.data?.subscriptionAddonsAdd?.success;

    if (isSuccessfulSubmission) {
      return onSubmitSuccess();
    }

    if (userErrors?.length) {
      return onSubmitError(userErrors);
    }
  }

  async function purchaseMutation() {
    if (!selectedAddonCode) {
      return;
    }

    try {
      const result = await addonsAdd({
        variables: {
          input: {
            addonCodes: [selectedAddonCode],
          },
        },
      });
      return result;
    } catch (error) {
      onSubmitError([
        { message: formatMessage(messages.defaultSubmitError), path: [] },
      ]);
      return undefined;
    }
  }

  function onSubmitSuccess() {
    showToast({
      message: formatMessage(messages.successfulUpdate),
      variation: "success",
    });
    setIsSubmitting(false);
    setModalOpen(false);
    if (successAction) {
      successAction();
    }
  }

  function onSubmitError(errors: MutationErrors[]) {
    setSubmissionErrors(errors.map(error => error.message));
    setIsSubmitting(false);
    trackFailedSubmitRecurlyForm(trackingDetails);
  }

  function onValidationError(newErrorState: FieldErrorState) {
    setValidationErrors({
      ...validationErrors,
      [newErrorState.field]: newErrorState.message,
    });
  }

  function clearValidationErrors(fieldNames: string[]) {
    const newErrorState = { ...validationErrors };
    fieldNames.forEach(fieldName => {
      newErrorState[fieldName] = "";
    });

    setValidationErrors(newErrorState);
  }

  function handleDismiss() {
    setModalOpen(!modalOpen);
    setSaveEnabled(false);
    trackInteractedWithButton({
      ...trackingDetails,
      interaction: DISMISS_INTERACTION,
    });

    if (addonUpsell) {
      addonUpsell.reset();
    }

    if (onDismiss) {
      onDismiss();
    }
  }
}
