import React from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import { DedicatedPhoneNumberSetting } from "jobber/settings/dedicatedPhoneNumber/DedicatedPhoneNumberSetting";
import type { TwilioRegistrationStatus } from "~/utilities/API/graphql";
import { UkKycRegistrationRequirementTypes } from "~/utilities/API/graphql";

export interface DedicatedPhoneNumberSettingWrapperProps {
  selectedNumber?: string;
  registrationStatus: TwilioRegistrationStatus;
  isSelectedNumberActive?: boolean;
  requiresRegistration: boolean;
  kycRegistrationRequirement: UkKycRegistrationRequirementTypes;
  aiReceptionistDiscoverable: boolean;
}

export function DedicatedPhoneNumberSettingWrapper({
  selectedNumber,
  registrationStatus,
  isSelectedNumberActive,
  requiresRegistration,
  kycRegistrationRequirement,
  aiReceptionistDiscoverable,
}: DedicatedPhoneNumberSettingWrapperProps) {
  return (
    <>
      {(kycRegistrationRequirement ===
        UkKycRegistrationRequirementTypes.NOT_REQUIRED ||
        selectedNumber) && (
        <APIProvider>
          <DedicatedPhoneNumberSetting
            selectedNumber={selectedNumber}
            registrationStatus={registrationStatus}
            isSelectedNumberActive={isSelectedNumberActive}
            requiresRegistration={requiresRegistration}
            aiReceptionistDiscoverable={aiReceptionistDiscoverable}
          />
        </APIProvider>
      )}
    </>
  );
}
