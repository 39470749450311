import { defineMessages } from "react-intl";

export const messages = defineMessages({
  signUpOrConnectContentSignUpHeading: {
    id: "gpsTrackingConnectModal.signUpOrConnect.signUpHeading",
    defaultMessage: "Need tracking devices?",
    description: "Heading for the sign up or connect content",
  },
  signUpOrConnectContentSignUpDescription: {
    id: "gpsTrackingConnectModal.signUpOrConnect.signUpDescription",
    defaultMessage:
      "Get tracking devices for your vehicles and create your account.",
    description: "Description for the sign up or connect content",
  },
  signUpOrConnectContentConnectHeading: {
    id: "gpsTrackingConnectModal.signUpOrConnect.connectHeading",
    defaultMessage: "Already have an account?",
    description: "Heading for the connect content",
  },
  signUpOrConnectContentConnectDescription: {
    id: "gpsTrackingConnectModal.signUpOrConnect.connectDescription",
    defaultMessage:
      "Use an existing account if you're already tracking your fleet with Force.",
    description: "Description for the connect content",
  },
  signUpOrConnectContentImageAlt: {
    id: "gpsTrackingConnectModal.signUpOrConnect.imageAlt",
    defaultMessage: "Fleet tracking connect",
    description: "Alt text for the sign up or connect image",
  },
  newScheduleInfoHeading: {
    id: "gpsTrackingConnectModal.newScheduleInfo.heading",
    defaultMessage: "You'll access the new schedule for fleet tracking",
    description: "Heading for the new schedule info content",
  },
  newScheduleInfoImageAlt: {
    id: "gpsTrackingConnectModal.newScheduleInfo.imageAlt",
    defaultMessage: "New Schedule Visual",
    description: "Alt text for the new schedule info image",
  },
  newScheduleInfoText1: {
    id: "gpsTrackingConnectModal.newScheduleInfo.text1",
    defaultMessage:
      "{appName} uses the new calendar and map views, which makes it easier than ever to visualize job locations—and now your team.",
    description: "Text 1 for the new schedule info content",
  },
  newScheduleInfoText2: {
    id: "gpsTrackingConnectModal.newScheduleInfo.text2",
    defaultMessage:
      "Your original schedule will remain accessible while we work to improve to the new schedule.",
    description: "Text 2 for the new schedule info content",
  },
});
