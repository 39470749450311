import React, {
  type MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { Grid } from "@jobber/components/Grid";
import { Page } from "@jobber/components/Page";
import { useMutation, useQuery } from "@apollo/client";
import { showToast } from "@jobber/components";
import { useOnMount } from "@jobber/hooks/useOnMount";
import { PurchaseFormContextProvider } from "jobber/billing/hooks/PurchaseFormContext";
import { useStoredUpdateResult } from "jobber/billing/hooks/useStoredUpdateResult";
import {
  EditBillingInfo,
  type EditBillingInfoRef,
} from "jobber/billing/components/EditBillingInfo";
import {
  CONFIRM_SUBSCRIPTION_INTERACTION,
  type TrackingDetails,
  trackAutoAddedAddon,
  trackFailedSubmitRecurlyForm,
  trackInteractedWithButton,
  trackInteractedWithInput,
} from "jobber/billing/utils/trackInteractions";
import type { SubscriptionAddonPreview } from "~/shared/billing/pricePreview/types";
import type {
  BillingCycleName,
  MutationErrors,
  SubscriptionUpdateMutation,
  SubscriptionUpdateMutationVariables,
  SubscriptionUpdatePayload,
} from "~/utilities/API/graphql";
import type {
  FieldErrorState,
  FormErrorState,
} from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import { csrfToken } from "utilities/csrfToken";
import { Rollbar } from "~/utilities/errors/Rollbar";
import { PurchaseFormErrors } from "jobber/billing/components/PurchaseFormErrors";
import { formatCurrency } from "utilities/formatCurrency";
import { useViewport } from "jobber/hooks/useViewport";
import { PrivacyMask } from "components/Observability/PrivacyMask";
import { SubscriptionAddonCards } from "jobber/billing/features/Checkout/components/SubscriptionAddonCards/SubscriptionAddonCards";
import {
  INTERACTED_WITH_EXPERIMENT,
  MarketingSuiteAddonBanner,
} from "jobber/billing/features/Checkout/components/MarketingSuiteAddon/MarketingSuiteAddonBanner";
import { MarketingSuiteAddonLoadingGlimmer } from "jobber/billing/features/Checkout/components/MarketingSuiteAddon/MarketingSuiteAddonLoadingGlimmer";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "../../Checkout.module.css";
import { CheckoutSummary } from "../CheckoutSummary";
import { messages } from "../../messages";
import {
  CHECKOUT_EXPERIMENT,
  SUBSCRIPTION_UPDATE,
} from "../../Checkout.graphql";

interface CheckoutProps {
  recurlyPublicKey: string;
  planSetIdentifier: string;
  billingCycleName: BillingCycleName;
  subscriptionAddons: SubscriptionAddonPreview[];
  loadingAddons: boolean;
  preselectedAddons?: string[];
  country: string;
}

const trackingDetails: TrackingDetails = {
  name: "Checkout",
};

// eslint-disable-next-line max-statements
export function CheckoutExperiment(checkoutProps: CheckoutProps) {
  const { formatMessage } = useIntl();
  const {
    recurlyPublicKey,
    planSetIdentifier,
    billingCycleName,
    subscriptionAddons,
    loadingAddons,
    preselectedAddons,
    country,
  } = checkoutProps;
  const editBillingInfoFormRef =
    useRef() as MutableRefObject<EditBillingInfoRef>;
  const [selectedBillingCycle, setSelectedBillingCycle] =
    useState<BillingCycleName>(billingCycleName);
  const [selectedAddonCodes, setSelectedAddonCodes] = useState<string[]>(
    preselectedAddons ?? [],
  );
  const [displayedPurchaseTotal, setDisplayedPurchaseTotal] = useState<
    number | undefined
  >();
  const [submissionErrors, setSubmissionErrors] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<FormErrorState>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { loading: checkoutExpLoading, data: checkoutExpTreatment } =
    useQuery(CHECKOUT_EXPERIMENT);
  const checkoutExpVariation = checkoutExpTreatment?.experiment?.variation;

  useEffect(() => {
    // This will track and show the success message when we have auto-added addons
    if (
      subscriptionAddons.length > 0 &&
      preselectedAddons?.length &&
      preselectedAddons?.length > 0
    ) {
      showToast({
        message: formatMessage(messages.successfulAutoAdded),
        variation: "success",
      });
      trackAutoAddedAddon({
        ...trackingDetails,
        addonName:
          preselectedAddons?.length > 1
            ? preselectedAddons.join(", ")
            : preselectedAddons[0],
        interaction: "addon_selection",
        action: "auto added",
      });
    }
  }, [preselectedAddons, formatMessage, subscriptionAddons]);

  useOnMount(() => {
    Amplitude.TRACK_EVENT(INTERACTED_WITH_EXPERIMENT, {
      experiment: "checkout_condensed_jobber_online",
      interaction: "viewed",
    });
  });

  const { setStoredUpdateBannerMessage, setStoredUpdateResult } =
    useStoredUpdateResult();

  const [subscriptionUpdate] = useMutation<
    SubscriptionUpdateMutation,
    SubscriptionUpdateMutationVariables
  >(SUBSCRIPTION_UPDATE);

  const hasValidationError = Object.values(validationErrors).some(
    error => !!error,
  );

  // COBRA KAI experiment: ticket to remove this code https://jobber.atlassian.net/browse/JOB-109858
  const { innerWidth } = useViewport();

  const checkoutBannerEnabled =
    checkoutExpVariation &&
    checkoutExpVariation !== "off" &&
    checkoutExpVariation !== "ineligible" &&
    innerWidth > 768;

  const isMarketingSuiteAvailable = subscriptionAddons.some(
    addon => addon.baseIdentifier === "marketing_suite",
  );

  return (
    <PurchaseFormContextProvider submitting={isSubmitting} country={country}>
      <div className={styles.container}>
        <Page title={formatMessage(messages.experimentalTitle)}>
          <Grid>
            <Grid.Cell size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 8 }}>
              <PurchaseFormErrors errors={submissionErrors} />
              <PrivacyMask>
                <EditBillingInfo
                  isPurchasing={true}
                  recurlyPublicKey={recurlyPublicKey}
                  ref={editBillingInfoFormRef}
                  trackingDetails={trackingDetails}
                  onSubmitSuccess={callPurchaseMutation}
                  onSubmitError={onSubmitError}
                  onValidationError={onValidationError}
                  clearValidationErrors={clearValidationErrors}
                />
                {!isSubmitting && !checkoutBannerEnabled && (
                  <SubscriptionAddonCards
                    subscriptionAddons={subscriptionAddons}
                    selectedAddonCodes={selectedAddonCodes}
                    onChangeSelectedAddons={(
                      addonsAdded: string[],
                      addonsRemoved: string[],
                    ) => {
                      addonsAdded.forEach(addonCode => {
                        trackAddonSelection(addonCode, "added");
                      });
                      addonsRemoved.forEach(addonCode => {
                        if (selectedAddonCodes.includes(addonCode)) {
                          trackAddonSelection(addonCode, "removed");
                        }
                      });
                      setSelectedAddonCodes(
                        selectedAddonCodes
                          .filter(code => !addonsRemoved.includes(code))
                          .concat(addonsAdded),
                      );
                    }}
                  />
                )}
                {checkoutBannerEnabled &&
                  (loadingAddons || checkoutExpLoading) && (
                    <MarketingSuiteAddonLoadingGlimmer />
                  )}
                {!isSubmitting &&
                  checkoutBannerEnabled &&
                  isMarketingSuiteAvailable && (
                    <MarketingSuiteAddonBanner
                      checkoutExpVariation={"compact"}
                      subscriptionAddons={subscriptionAddons}
                      selectedAddonCodes={selectedAddonCodes}
                      setSelectedAddonCodes={setSelectedAddonCodes}
                    />
                  )}
              </PrivacyMask>
            </Grid.Cell>
            <Grid.Cell size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
              <PrivacyMask disabled>
                <CheckoutSummary
                  trackingDetails={trackingDetails}
                  planSetIdentifier={planSetIdentifier}
                  selectedBillingCycle={selectedBillingCycle}
                  enablePurchaseButton={!hasValidationError}
                  subscriptionAddons={subscriptionAddons}
                  selectedAddonCodes={selectedAddonCodes}
                  displayedPurchaseTotal={displayedPurchaseTotal}
                  onSetSelectedBillingCycle={setSelectedBillingCycle}
                  onSetPurchaseTotal={handlePurchaseTotalUpdate}
                  onConfirmSubscription={handleConfirmSubscription}
                  setSelectedAddons={setSelectedAddonCodes}
                />
              </PrivacyMask>
            </Grid.Cell>
          </Grid>
        </Page>
      </div>
    </PurchaseFormContextProvider>
  );

  function handlePurchaseTotalUpdate(purchaseTotal: number) {
    setDisplayedPurchaseTotal(purchaseTotal);
  }

  async function handleConfirmSubscription() {
    setSubmissionErrors([]);
    setIsSubmitting(true);
    trackInteractedWithButton({
      ...trackingDetails,
      interaction: CONFIRM_SUBSCRIPTION_INTERACTION,
      addonCodes: selectedAddonCodes,
    });

    editBillingInfoFormRef.current &&
      (await editBillingInfoFormRef.current.submit());
  }

  async function callPurchaseMutation() {
    const result = await purchaseMutation();

    const userErrors: MutationErrors[] | undefined =
      result?.data?.subscriptionUpdate?.userErrors;

    const hasAddonErrors = hasAddonPurchaseError(userErrors);

    if (result?.data?.subscriptionUpdate?.success) {
      return onSubmitSuccess(result.data.subscriptionUpdate, hasAddonErrors);
    }
    if (userErrors?.length) {
      return onSubmitError(userErrors);
    }
  }

  async function purchaseMutation() {
    try {
      const result = await subscriptionUpdate({
        variables: {
          input: {
            planSetIdentifier: planSetIdentifier,
            billingCycleName: selectedBillingCycle,
            selectedAddonCodes: selectedAddonCodes,
            displayedPurchaseTotal: displayedPurchaseTotal
              ? formatCurrency(displayedPurchaseTotal, "$", 2)
              : undefined,
          },
        },
      });
      return result;
    } catch (error) {
      onSubmitError([
        { message: formatMessage(messages.defaultSubmitError), path: [] },
      ]);
    }
  }

  async function onSubmitSuccess(
    result: SubscriptionUpdatePayload,
    hasAddonErrors?: boolean,
  ) {
    if (result.shouldRecordFirstTimeSubscriptionEvents) {
      await handlePostSubscriptionUpdates();
    }

    const addonsMessage = hasAddonErrors
      ? result.userErrors[0].message
      : undefined;

    if (result.successRedirectUrl) {
      return redirect(result.successRedirectUrl, addonsMessage);
    }
  }

  function redirect(url: string, addonsMessage?: string) {
    if (addonsMessage) {
      setStoredUpdateBannerMessage(addonsMessage);
    } else {
      setStoredUpdateResult(formatMessage(messages.successfulUpdate));
    }
    window.location.href = url;
  }

  function onSubmitError(errors: MutationErrors[]) {
    setIsSubmitting(false);
    setSubmissionErrors(errors.map(error => error.message));
    trackFailedSubmitRecurlyForm(trackingDetails);
  }

  function onValidationError(newErrorState: FieldErrorState) {
    const fieldName = newErrorState.field;

    setValidationErrors({
      ...validationErrors,
      [fieldName]: newErrorState.message,
    });
  }

  function clearValidationErrors(fieldNames: string[]) {
    const newErrorState = { ...validationErrors };
    fieldNames.forEach(fieldName => {
      newErrorState[fieldName] = "";
    });

    setValidationErrors(newErrorState);
  }

  async function handlePostSubscriptionUpdates() {
    const headers = new Headers([
      ["X-CSRF-Token", csrfToken],
      ["Content-type", "application/json"],
    ]);

    const request = new Request("checkout/set_first_subscription_events", {
      method: "PUT",
      credentials: "include",
      headers,
    });

    try {
      await fetch(request);
    } catch (error) {
      Rollbar.EXECUTE(
        `Post subscription updates failed: ${error.message}`,
        new Error("Checkout"),
      );
    }
  }

  function trackAddonSelection(addonCode: string, action: string) {
    const addonName = subscriptionAddons.find(
      addon => addon.monthlyBillingCycle?.addonCode === addonCode,
    )?.name;

    trackInteractedWithInput({
      ...trackingDetails,
      addonName: addonName,
      interaction: "addon_selection",
      action: action,
    });
  }
}

function hasAddonPurchaseError(userErrors: MutationErrors[] | undefined) {
  return userErrors?.some(userError => {
    return userError.path.includes("addons_purchase");
  });
}
