import React, { useContext, useEffect, useState } from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Banner } from "@jobber/components/Banner";
import { Text } from "@jobber/components/Text";
import { TwilioRegistrationMessage } from "jobber/chat/components/ChatDrawer/TwilioRegistrationMessage";
import { LinkContext } from "jobber/settings/dedicatedPhoneNumber/TextMessagingSettingsPage";
import { usePhoneNumbers } from "jobber/settings/dedicatedPhoneNumber/usePhoneNumbers";
import { SelectAndSaveModal } from "jobber/settings/dedicatedPhoneNumber/selectAndSaveModal/SelectAndSaveModal";
import { TwilioRegistrationStatus } from "~/utilities/API/graphql";

interface PhoneNumberSelectionProps {
  selectedNumber?: string;
  registrationStatus: TwilioRegistrationStatus;
  isSelectedNumberActive?: boolean;
  requiresRegistration: boolean;
  aiReceptionistDiscoverable: boolean;
}

export function DedicatedPhoneNumberSetting({
  selectedNumber,
  registrationStatus,
  isSelectedNumberActive,
  requiresRegistration,
  aiReceptionistDiscoverable,
}: PhoneNumberSelectionProps) {
  const [modalOpen, setModalOpen] = useState<boolean>(
    window.location.search.includes("grow_trial"),
  );
  const [findPhoneNumbers, loading, numbers, error] = usePhoneNumbers();
  const [displayNumber, setDisplayNumber] = useState<
    string | number | undefined
  >(selectedNumber);

  const hasNumber = displayNumber != undefined;

  const description = DedicatedPhoneNumberSettingDescription({
    hasNumber,
    aiReceptionistDiscoverable,
  });

  const isStatusNeedsBanner = [
    TwilioRegistrationStatus.REGISTERED,
    TwilioRegistrationStatus.REGISTRATION_NOT_REQUIRED,
    TwilioRegistrationStatus.UNKNOWN_REGISTRATION_STATUS,
  ].includes(registrationStatus);

  useEffect(() => {
    if (window.location.search.includes("grow_trial") && !hasNumber) {
      findPhoneNumbers();
    }
  }, []);

  return (
    <Content>
      {error && <Banner type="error">{error}</Banner>}
      <div className="row">
        <div className="columns u-paddingNone">
          {hasNumber && !isSelectedNumberActive && !isStatusNeedsBanner && (
            <div
              style={{ marginBottom: "var(--space-large)" }}
              data-testid="registration-message"
            >
              <Banner
                type="notice"
                dismissible={false}
                primaryAction={{
                  label: "Learn More",
                  type: "tertiary",
                  size: "small",
                  url: "https://help.getjobber.com/hc/en-us/articles/1500008684501-Register-Your-Number",
                  external: true,
                }}
              >
                <Text>
                  <TwilioRegistrationMessage status={registrationStatus} />
                </Text>
              </Banner>
            </div>
          )}
          {hasNumber && (
            <p>
              Your dedicated phone number is{" "}
              <span className="u-textBold">{displayNumber}</span>
            </p>
          )}
          {description}
        </div>
        {numbers.length !== 0 && modalOpen && (
          <SelectAndSaveModal
            title={
              requiresRegistration
                ? "Step 1: Choose your number"
                : "Choose your number"
            }
            open={modalOpen}
            phoneNumberOptions={numbers}
            requiresRegistration={requiresRegistration}
            onRequestClose={handleClose}
            handleAfterSave={afterSave}
            getPhoneNumberOptions={findPhoneNumbers}
          >
            <Content>
              <p>
                This phone number is dedicated to your business, so your clients
                will always know it’s you. Choose from the following available
                phone numbers.
              </p>
              <p className={"u-marginBottomNone"}>
                Don’t see a phone number with your area code? Check back in a
                few days!
              </p>
            </Content>
          </SelectAndSaveModal>
        )}
      </div>
      {!hasNumber && (
        <div>
          <Button label="Get Started" loading={loading} onClick={toggleModal} />
        </div>
      )}
    </Content>
  );

  function handleClose() {
    setModalOpen(false);
  }

  function afterSave(newNumber: string | undefined) {
    if (newNumber !== undefined) {
      setDisplayNumber(newNumber);
    }
  }

  function toggleModal() {
    if (numbers.length === 0) {
      findPhoneNumbers();
    }

    setModalOpen(true);
  }
}

interface DedicatedPhoneNumberSettingDescriptionProps {
  hasNumber: boolean;
  aiReceptionistDiscoverable: boolean;
}

function DedicatedPhoneNumberSettingDescription({
  hasNumber,
  aiReceptionistDiscoverable,
}: DedicatedPhoneNumberSettingDescriptionProps) {
  const companySettingsPath = useContext(LinkContext);
  const companySettingsLink = (
    <a href={companySettingsPath}>Company Settings</a>
  );
  return hasNumber ? (
    <p>
      All of your account’s text messages, including reminders, notifications
      and follow-ups are sent from this phone number. Calls made to your
      dedicated phone number will forward to the phone number listed in{" "}
      {companySettingsLink}.
    </p>
  ) : (
    <div>
      <p>
        Choose a local phone number that&apos;s unique to your business. A
        dedicated phone number in Jobber is required to:
      </p>
      <ul className="list list--bulleted u-paddingLeft">
        <li>
          Send text messages from Jobber (like quotes, invoices, or automated
          reminders) from a consistent number that clients can recognize as your
          business
        </li>
        <li>Text back and forth with customers in Jobber</li>
        {aiReceptionistDiscoverable && <li>Set up your AI Receptionist</li>}
      </ul>
    </div>
  );
}
