import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Banner, Card, Content } from "@jobber/components";
import { messages } from "~/jobber/invoices/components/AchOfflinePLEModal/messages";
import type { ClientHubSettingsQuery } from "~/utilities/API/graphql";
import {
  InvoiceOrQuote,
  JobberPaymentsDefaultPaymentPreference,
} from "~/utilities/API/graphql";
import { CLIENT_HUB_SETTING_QUERY } from "jobber/managed_accounts/Settings/ClientHubSettingQuery";
import { DefaultPaymentRadioGroup } from "./DefaultPaymentRadioGroup";

const genericError = new Error("Could not update settings");
declare const window: Window & { dataLayer: Record<string, unknown>[] };

interface PaymentPreferencesSettingsProps {
  objectType: InvoiceOrQuote;
  onPreferenceChange?: (value: JobberPaymentsDefaultPaymentPreference) => void;
}

//eslint-disable-next-line max-statements
export function PaymentPreferencesSettings({
  objectType,
  onPreferenceChange,
}: PaymentPreferencesSettingsProps) {
  const { formatMessage } = useIntl();
  const [mutationErrorMessage, setMutationErrorMessage] = useState("");

  const { loading, error, data } = useQuery<ClientHubSettingsQuery>(
    CLIENT_HUB_SETTING_QUERY,
    {
      onError: () => {
        setMutationErrorMessage(
          formatMessage(messages.defaultMutationErrorMessage),
        );
      },
    },
  );

  const [isAchEnabled, setIsAchEnabled] = useState<boolean>();

  const title =
    objectType === InvoiceOrQuote.INVOICE
      ? "Invoices Preferences"
      : "Quotes Preferences";

  const [preference, setDefaultPreference] = useState<
    JobberPaymentsDefaultPaymentPreference | string
  >(JobberPaymentsDefaultPaymentPreference.ACH_AND_CREDIT_CARD);

  useEffect(() => {
    if (data) {
      setIsAchEnabled(data.achPayments?.enabled ?? false);
    }
  }, [data]);

  if (loading) {
    return <div className="spinner" />;
  }

  if (error || !data) {
    return (
      <Content>
        <Banner type="error" onDismiss={() => setMutationErrorMessage("")}>
          {formatMessage(messages.defaultMutationErrorMessage)}
        </Banner>
      </Content>
    );
  }

  return (
    <Content>
      <Card
        header={{
          title: title,
        }}
      >
        <Content>
          {mutationErrorMessage && (
            <Banner type="error" onDismiss={onErrorBannerDismiss}>
              {mutationErrorMessage}
            </Banner>
          )}
          <DefaultPaymentRadioGroup
            handleOnChange={handleRadioOnChange}
            selectedOption={preference}
            objectType={objectType}
            onError={handleMutationError}
            isAchEnabled={isAchEnabled}
            setIsAchEnabled={setIsAchEnabled}
            shouldSaveOnChange={false}
          />
        </Content>
      </Card>
    </Content>
  );

  function handleMutationError(mutationError: Error) {
    setMutationErrorMessage((mutationError || genericError).message);
  }
  function onErrorBannerDismiss() {
    setMutationErrorMessage("");
  }

  function handleRadioOnChange(value: JobberPaymentsDefaultPaymentPreference) {
    setDefaultPreference(value);
    sendGoogleTagManagerEvent(objectType, value);
    onPreferenceChange?.(value);
  }

  function sendGoogleTagManagerEvent(
    type: InvoiceOrQuote,
    value: JobberPaymentsDefaultPaymentPreference,
  ) {
    window.dataLayer?.push({
      event:
        type === InvoiceOrQuote.INVOICE
          ? "select_invoice_payment_option"
          : "select_quote_payment_option",
      option: value,
    });
  }
}
