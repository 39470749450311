import React from "react";
import { Text } from "@jobber/components/Text";
import { type MessageDescriptor, useIntl } from "react-intl";
import { InlineLabel } from "@jobber/components";
import { PriceHighlightText } from "legacy/jobber/billing/components/PriceHighlightText/PriceHighlightText";
import {
  BillingCycleName,
  type SubscriptionAddon,
} from "~/shared/billing/pricePreview/types";
import { getDiscountHeader } from "legacy/jobber/billing/features/Checkout/utils";
import { messages } from "./messages";
import styles from "./AddonDetails.module.css";

interface AddonDetailsProps {
  subscriptionAddon: SubscriptionAddon;
  omitDescription?: boolean;
}

const addonToDescriptionMap: { [key: string]: MessageDescriptor } = {
  marketing_suite: messages.marketingSuiteDescription,
  campaigns: messages.campaignsDescription,
  reviews: messages.reviewsDescription,
  referrals: messages.referralsDescription,
  ai_receptionist: messages.aiReceptionistDescription,
};

export function AddonDetails({
  subscriptionAddon,
  omitDescription,
}: AddonDetailsProps) {
  const { baseIdentifier, currentBillingCycle, isActive, isCancelled } =
    subscriptionAddon;

  const addonDescription = addonToDescriptionMap[baseIdentifier];

  const { formatMessage } = useIntl();

  const pillContent = pillText();

  const billingCycle =
    subscriptionAddon.currentBillingCycle ||
    subscriptionAddon.monthlyBillingCycle;
  return (
    <>
      <div className={styles.footer}>
        <PriceHighlightText
          cost={billingCycle?.monthlyCost}
          discountedCost={
            subscriptionAddon.discountGroup?.monthlyAddonDiscount
              ?.addonCostMonthlyDiscounted
          }
          hideDiscountedCost={
            subscriptionAddon.discountGroup?.monthlyAddonDiscount
              ?.hasMultipleActiveDiscounts
          }
        />
        {pillContent && (
          <InlineLabel color={isCancelled ? "orange" : "green"}>
            {pillContent}
          </InlineLabel>
        )}
      </div>
      {addonDescription && !omitDescription && (
        <Text>{formatMessage(addonDescription)}</Text>
      )}
    </>
  );

  function pillText() {
    if (currentBillingCycle && isActive) {
      const { renewalDate, futureStartBillingOnDate } = currentBillingCycle;
      const renewalDateMessage = isCancelled
        ? formatMessage(messages.expiry, {
            expiryDate: renewalDate,
          })
        : formatMessage(messages.renewal, {
            renewalDate: renewalDate,
          });

      return futureStartBillingOnDate
        ? formatMessage(messages.futureBilled, {
            futureStartBillingOnDate: futureStartBillingOnDate,
          })
        : renewalDateMessage;
    }

    return getDiscountHeader(
      formatMessage,
      BillingCycleName.MONTHLY,
      subscriptionAddon.discountGroup?.monthlyAddonDiscount?.discountType,
      subscriptionAddon.discountGroup?.monthlyAddonDiscount?.discountAmount,
      subscriptionAddon.discountGroup?.monthlyAddonDiscount
        ?.remainingDiscountedFullMonths,
    );
  }
}
